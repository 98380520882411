import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabOrderItemSKUType } from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';
import type { IHold, HoldCategory, HoldSubcategory } from '@orthly/shared-types';
import { HoldUtils, PauseOrderActionReason } from '@orthly/shared-types';
import { QuickForm } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

interface EditHoldFormProps {
    show_expected_delay_days?: boolean;
    can_show_practice: boolean;
    show_only_lab_visible_categories: boolean;
    onSubmit: (hold: IHold) => Promise<void>;
    initialValue?: IHold;
    labOrder: LabsGqlLabOrderFragment;
}

// Quickform <select> unfortunately only supports string values.
// We'd like for any category for the user to be able to pick `null` for the subcategory, since we
// represent the freeform subcategory as null in the database. Instead of being able to pass null
// as a value of a select option, we'll use the following sentinel and be sure to check for it.
type FreeformSubcategoryIdType = '<freeform subcategory>';
const freeformSubcategoryIdValue: FreeformSubcategoryIdType = '<freeform subcategory>';
type SubcategoryIdFormFieldType = string | FreeformSubcategoryIdType;

type FormFields = {
    category_id: string;
    // no category selected -> subcategory_uuid = undefined (form not submittable)
    // category selected, but subcategory not selected -> subcategory_uuid = undefined (form not submittable)
    // category has no subcategories -> subcategory_uuid = undefined
    // category selected, but "Other (Please explain)" selected for subcategory -> subcategory_uuid = freeformSubcategoryIdValue
    // category selected, subcategory selected -> subcategory_uuid is a HoldSubcategoryUUID
    subcategory_id?: string;
    further_info: string;
    show_practice: boolean;
};

type SubcategoryFormOption = HoldSubcategory & {
    id: string | FreeformSubcategoryIdType;
    label: string;
    requires_further_info: boolean;
    sku_list?: LabOrderItemSKUType[];
};

function useInitialFormValues(initial_value?: IHold): Partial<FormFields> {
    return React.useMemo(() => {
        if (!!initial_value) {
            return {
                category_id: initial_value.category_id ?? undefined,
                subcategory_id: initial_value.subcategory_id ?? undefined,
                further_info: initial_value.further_info ?? '',
                // if can_show_practice = false && initialValue.show_to_practice = true,
                // we'll preserve the original value, but hide the field so the user can't edit it.
                show_practice: initial_value.show_to_practice,
            };
        }
        return {
            further_info: '',
            // if can_show_practice = false, show_practice must be false and uneditable
            // even if can_show_practice = true, show_practice should start false
            show_practice: false,
        };
    }, [initial_value]);
}

function useHoldSubcategoryOptionsForCategory(
    holdCategoryId: string | undefined,
    only_show_lab_visible: boolean,
): SubcategoryFormOption[] {
    return React.useMemo(() => {
        // no category selected, no subcategories to show
        if (!holdCategoryId) {
            return [];
        }

        const subcategories = HoldUtils.hardcodedHoldSubcategories.filter(
            subcategory =>
                subcategory.parent_category_id === holdCategoryId &&
                (subcategory.lab_visible || !only_show_lab_visible),
        );

        if (subcategories.length === 0) {
            return [];
        }

        // if there are subcategories, always give an extra option for freeform input
        return subcategories;
    }, [holdCategoryId, only_show_lab_visible]);
}

function useFurtherInfoRequiredForCategoryAndSubcategory(
    selectedCategory: HoldCategory | undefined,
    subcategoryOptions: SubcategoryFormOption[],
    selectedSubcategory: SubcategoryFormOption | undefined,
): boolean {
    return React.useMemo(() => {
        // if no category is selected, the form will be invalid anyway, because category is a required field.
        // We don't want to add noise by making the further info field invalid as well.
        if (!selectedCategory) {
            return false;
        }

        // if we have a category with no subcategories, the category decides whether or not further info is required.
        if (subcategoryOptions.length === 0) {
            return selectedCategory.requires_further_info;
        }

        // if there are subcategories to pick from, but none is selected, the form will be invalid anyway, because
        // a subcategory is required. We don't want to add noise by making the further info field invalid as well.
        if (!selectedSubcategory) {
            return false;
        }

        return selectedSubcategory.requires_further_info;
    }, [selectedCategory, subcategoryOptions, selectedSubcategory]);
}

export const EditHoldForm: React.VFC<EditHoldFormProps> = props => {
    const initialFormValues = useInitialFormValues(props.initialValue);

    const [holdCategoryId, setHoldCategoryId] = React.useState<string | undefined>(initialFormValues.category_id);
    const selectedHoldCategory: HoldCategory | undefined = React.useMemo(() => {
        return !holdCategoryId
            ? undefined
            : HoldUtils.hardcodedHoldCategories.find(category => category.id === holdCategoryId);
    }, [holdCategoryId]);

    const holdSubcategoryOptions = useHoldSubcategoryOptionsForCategory(
        holdCategoryId,
        props.show_only_lab_visible_categories,
    );

    const [holdSubcategoryId, setHoldSubcategoryId] = React.useState<SubcategoryIdFormFieldType | undefined>(
        initialFormValues.subcategory_id,
    );
    const selectedHoldSubcategory: SubcategoryFormOption | undefined = React.useMemo(() => {
        return holdSubcategoryOptions.find(sco => sco.id === holdSubcategoryId);
    }, [holdSubcategoryId, holdSubcategoryOptions]);

    const further_info_required = useFurtherInfoRequiredForCategoryAndSubcategory(
        selectedHoldCategory,
        holdSubcategoryOptions,
        selectedHoldSubcategory,
    );

    const doesOrderIntersectWithSkuList = (skuList: LabOrderItemSKUType[] | undefined) => {
        if (!skuList) {
            return true;
        }
        return !!props.labOrder.items_v2.find(item => {
            return skuList.includes(item.sku as string as LabOrderItemSKUType);
        });
    };

    const isDoctorRequestedHoldCategory = holdCategoryId === '369673fc-31b6-50ee-ad06-9ce54beede3b';

    return (
        <QuickForm<FormFields>
            fields={{
                category_id: {
                    label: 'Issue',
                    type: 'select',
                    disableSortOptions: true,
                    options: HoldUtils.hardcodedHoldCategories
                        .filter(category => !category.deprecated)
                        .filter(category => category.lab_visible || !props.show_only_lab_visible_categories)
                        .filter(category => doesOrderIntersectWithSkuList(category.sku_list))
                        .filter(category => !category.hidden_from_ops)
                        .map(category => ({
                            value: category.id,
                            label: category.label,
                        })),
                },
                subcategory_id: {
                    label: 'Reason',
                    type: 'select',
                    disableSortOptions: true,
                    optional: holdSubcategoryOptions.length === 0,
                    hidden: holdSubcategoryOptions.length === 0,
                    options: holdSubcategoryOptions
                        .filter(subcategory => doesOrderIntersectWithSkuList(subcategory.sku_list))
                        .filter(subcategory => !subcategory.deprecated)
                        .filter(subcategory => subcategory.lab_visible || !props.show_only_lab_visible_categories)
                        .filter(subcategory => !belongsToEnum(subcategory.label, PauseOrderActionReason))
                        .map(subcategory_option => ({
                            value: subcategory_option.id,
                            label: subcategory_option.label,
                        })),
                },
                further_info: {
                    type: 'text',
                    hidden: !holdCategoryId,
                    // only required when "Other" is chosen for the category or subcategory
                    optional: !further_info_required,
                    label: further_info_required ? 'Additional Info' : 'Additional Info (not needed)',
                    fieldProps: { multiline: true, minRows: 1, maxRows: 100 },
                },
                show_practice: {
                    label: 'Visible to Practice',
                    type: 'boolean',
                    hidden: !props.can_show_practice,
                },
            }}
            initialValues={initialFormValues}
            onChange={(result, formikProps) => {
                if (result.category_id !== holdCategoryId) {
                    setHoldCategoryId(result.category_id ?? undefined);
                    // the options for this change on issue change so we want to reset it
                    setHoldSubcategoryId(undefined);
                    formikProps.setFieldValue('subcategory_id', undefined);
                    return;
                }
                if (result.subcategory_id !== holdSubcategoryId) {
                    setHoldSubcategoryId(result.subcategory_id ?? undefined);
                }
            }}
            onSubmit={async result => {
                await props.onSubmit({
                    category_id: result.category_id,
                    subcategory_id:
                        result.subcategory_id === freeformSubcategoryIdValue ? null : result.subcategory_id ?? null,
                    further_info: result.further_info?.trim() || null,
                    show_to_practice: result.show_practice,
                });
            }}
        >
            {isDoctorRequestedHoldCategory && (
                <Text variant={'body2'} style={{ padding: '12px 0' }}>
                    Looking for practice managed pause options? First remove any internal holds, then you can create a
                    practice managed pause from a separate option in the edit statuss dropdown.
                </Text>
            )}
        </QuickForm>
    );
};
