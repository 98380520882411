/* eslint-disable max-lines */
import { useFirebaseFileDownload, useFirebasePreviewMulti } from '../../hooks';
import { DandyLightbox, isImagePath } from '../DandyLightbox';
import { OrderDetailBlock } from '../OrderDetails';
import { ItemRefabReasonCodesEditorChip } from './ItemRefabReasonCodesEditorChip';
import { RefabGeneralNotesEditorChip } from './RefabGeneralNotesEditorChip';
import type { FormattedShadeUpdate } from './utils';
import { getFormattedShadeUpdates } from './utils';
import { isShadeReasonByCategoryName } from '@orthly/dentin';
import type { LabsGqlOrder, LabsGqlReviewTagSubmissionFragment } from '@orthly/graphql-operations';
import { useGetReviewQuery, useListChatMessagesQuery, useOrder } from '@orthly/graphql-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { UuidUtils } from '@orthly/runtime-utils';
import type { SimpleMenuPropsItem } from '@orthly/ui';
import { OrderPaperIcon, SimpleMenu } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    stylesFactory,
    Text,
    Chip,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    MoreVertIcon,
    RedoIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import path from 'path-browserify';
import React from 'react';

const useStyles = stylesFactory(() => ({
    block: {
        paddingBottom: 16,
    },
    blockLeft: {
        width: 140,
    },
    chips: {
        height: 24,
        marginRight: 8,
        marginBottom: 8,
        borderRadius: 2,
    },
    annotationRowWrapper: {
        width: 110,
        display: 'inline-block',
        marginRight: 16,
        marginBottom: 16,
    },
    annotationPreview: {
        width: 110,
        height: 72,
    },
    annotationPreviewImageWrapper: {
        display: 'inline-block',
        maxHeight: 72,
        maxWidth: 110,
        margin: 'auto',
    },
    filePreviewRowWrapper: {
        width: 188,
        display: 'inline-block',
        marginRight: 16,
        marginBottom: 16,
    },
    filePreviewRow: {
        '&:hover': {
            cursor: 'pointer',
        },
        height: 60,
    },
    filePreview: {
        width: 60,
        height: 60,
        borderRadius: 8,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    filePreviewImageWrapper: {
        display: 'inline-block',
        maxHeight: 56,
        maxWidth: 56,
        margin: 'auto',
    },
    filePreviewLabel: {
        width: 112,
        marginLeft: 16,
        margin: 'auto 0',
    },
}));

interface RefabBlockSectionRowProps {
    // An optional bolded headline, e.g. `Crown 3`.
    boldTitle?: string;
    // The grayed section string shown in the left hand side of the row.
    section: string;
    // The content on the right hand side of the row.
    content: React.ReactNode | string;
    showDivider?: boolean;
}

const RefabBlockSectionRow: React.VFC<RefabBlockSectionRowProps> = ({ boldTitle, section, content, showDivider }) => {
    const classes = useStyles();

    return (
        <Grid container item direction={'column'}>
            {boldTitle && (
                <Grid item>
                    <Text variant={'body2'} medium>
                        {boldTitle}
                    </Text>
                </Grid>
            )}
            <Grid item container direction={'row'}>
                <Grid item className={classes.blockLeft}>
                    <Text variant={'body2'} color={'GRAY'}>
                        {section}
                    </Text>
                </Grid>
                <Grid item xs>
                    {typeof content === 'string' ? (
                        <Text variant={'body2'} color={'BLACK'}>
                            {content}
                        </Text>
                    ) : (
                        content
                    )}
                </Grid>
            </Grid>
            {showDivider && <Divider style={{ margin: `8px 0px` }} />}
        </Grid>
    );
};

const RefabTitle: React.VFC<{ label: string }> = ({ label }) => {
    return (
        <Grid container component={'span'} alignItems={'center'} style={{ color: FlossPalette.ATTENTION }}>
            <RedoIcon color={'error'} fontSize={'small'} style={{ marginRight: 8, color: FlossPalette.ATTENTION }} />
            {label}
        </Grid>
    );
};

const OrderDetailRefabBlockItem: React.VFC<{
    orderId: string;
    item: IOrderItemV2DTO;
    tags: LabsGqlReviewTagSubmissionFragment[];
    showDivider: boolean;
    showRefabEditChips?: boolean;
    refabShadeUpdates: FormattedShadeUpdate[];
}> = ({ orderId, item, tags, showDivider, showRefabEditChips, refabShadeUpdates }) => {
    const classes = useStyles();

    return (
        <RefabBlockSectionRow
            showDivider={showDivider}
            boldTitle={CartItemV2Utils.getFullDisplayName(item)}
            section={`Refab Reason`}
            content={
                <Grid container direction={'column'}>
                    <Grid item>
                        {tags.map(tag => (
                            <Chip
                                key={`refab_reason_chip${tag.title}`}
                                className={classes.chips}
                                style={{
                                    backgroundColor: tag.title === 'Other' ? FlossPalette.BLACK : FlossPalette.GRAY,
                                }}
                                label={
                                    <Text
                                        variant={'caption'}
                                        color={'WHITE'}
                                        medium
                                    >{`${tag.category} (${tag.title})`}</Text>
                                }
                            />
                        ))}
                        <ItemRefabReasonCodesEditorChip
                            orderId={orderId}
                            itemId={item.id}
                            itemDisplayName={CartItemV2Utils.getDisplayName(item)}
                            savedTags={tags}
                            showRefabEditChips={showRefabEditChips}
                        />
                    </Grid>
                    {refabShadeUpdates.length > 0 && (
                        <Grid item>
                            {refabShadeUpdates.map(update => (
                                <Grid item key={update.shadeName}>
                                    <Text
                                        variant={'caption'}
                                        color={'DARK_GRAY'}
                                        style={{ minWidth: `110px`, display: `inline-block` }}
                                    >
                                        {update.shadeName}
                                    </Text>
                                    <Chip
                                        className={classes.chips}
                                        style={{
                                            backgroundColor: FlossPalette.BLACK,
                                            marginTop: 8,
                                        }}
                                        label={
                                            <Text variant={'caption'} color={'WHITE'} medium>
                                                {update.changeDescription}
                                            </Text>
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    <Grid item>
                        {tags
                            .filter(tag => tag.title.toLowerCase() === 'other' && !!tag.other_notes)
                            .map(tag => (
                                <Text key={`refab_reason_notes_${tag.title}`} variant={'body2'}>
                                    {tag.category}: <em>{tag.other_notes}</em>
                                </Text>
                            ))}
                    </Grid>
                </Grid>
            }
        />
    );
};

interface OrderDetailRefabFileProps {
    url: string;
    onPreviewEnlarged?: () => void;
    previewUrl?: string;
}

const OrderDetailRefabAnnotation: React.VFC<OrderDetailRefabFileProps> = ({ url, onPreviewEnlarged, previewUrl }) => {
    const fileName = path.basename(url);
    const classes = useStyles();
    const downloadAttachmentFromFirebase = useFirebaseFileDownload(url, fileName);
    return (
        <Tooltip title={fileName}>
            <div className={classes.annotationRowWrapper}>
                <Grid
                    container
                    direction={'row'}
                    className={classes.filePreviewRow}
                    onClick={() => (onPreviewEnlarged ? onPreviewEnlarged() : downloadAttachmentFromFirebase.execute())}
                >
                    <Grid item className={classes.annotationPreview}>
                        <div className={classes.annotationPreviewImageWrapper}>
                            <img src={previewUrl} style={{ height: '100%', width: '100%' }} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Tooltip>
    );
};

const OrderDetailRefabFile: React.VFC<OrderDetailRefabFileProps> = ({ url, onPreviewEnlarged, previewUrl }) => {
    const fileName = path.basename(url);
    const shortenedFileName = fileName.length > 12 ? `${fileName.substring(0, 12)}...` : fileName;

    const classes = useStyles();
    const downloadAttachmentFromFirebase = useFirebaseFileDownload(url, fileName);

    return (
        <Tooltip title={fileName}>
            <div className={classes.filePreviewRowWrapper}>
                <Grid
                    container
                    direction={'row'}
                    className={classes.filePreviewRow}
                    onClick={() => (onPreviewEnlarged ? onPreviewEnlarged() : downloadAttachmentFromFirebase.execute())}
                >
                    <Grid item className={classes.filePreview}>
                        {previewUrl ? (
                            <div className={classes.filePreviewImageWrapper}>
                                <img src={previewUrl} style={{ height: '100%', width: '100%' }} />
                            </div>
                        ) : (
                            <OrderPaperIcon style={{ margin: 21 }} />
                        )}
                    </Grid>
                    <Grid item xs className={classes.filePreviewLabel}>
                        <Text variant={'body2'} color={'BLACK'} medium>
                            {shortenedFileName}
                        </Text>
                    </Grid>
                </Grid>
            </div>
        </Tooltip>
    );
};

interface OrderDetailRefabFilesProps {
    urls: string[];
    orderId: string;
    showDivider?: boolean;
}

const OrderDetailRefabFiles: React.VFC<OrderDetailRefabFilesProps> = ({ urls, orderId }) => {
    const { imageAttachmentUrls, otherAttachmentUrls } = React.useMemo(() => {
        return {
            imageAttachmentUrls: urls.filter(url => isImagePath(url)),
            otherAttachmentUrls: urls.filter(url => !isImagePath(url)),
        };
    }, [urls]);

    // Loads firebase URLs since we currently just have Firebase paths in the attachments list.
    const { result: previews, loading } = useFirebasePreviewMulti(
        imageAttachmentUrls.map(url => ({ source: url, name: url })),
    );
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    return (
        <RefabBlockSectionRow
            section={'Files'}
            content={
                <Grid>
                    {selectedAttachmentPreview && (
                        <DandyLightbox
                            setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                            loading={loading}
                            previews={previews}
                            selectedAttachmentPreview={selectedAttachmentPreview}
                            onPhotoViewedAnalytics={(source, name) => {
                                return {
                                    name: 'All - Portal - Photo Lightbox Viewed',
                                    data: {
                                        $groups: { order: orderId },
                                        displayLocation: 'order_refab_block',
                                        photoSource: source,
                                        photoName: name,
                                    },
                                };
                            }}
                        />
                    )}
                    {imageAttachmentUrls.map((url, idx) => (
                        <OrderDetailRefabFile
                            key={`refab_attachment_photo_${idx}`}
                            url={url}
                            previewUrl={previews?.[idx]?.source}
                            onPreviewEnlarged={() => setSelectedAttachmentPreview(previews?.[idx]?.name)}
                        />
                    ))}
                    {otherAttachmentUrls.map((url, idx) => (
                        <OrderDetailRefabFile key={`refab_attachment_other_${idx}`} url={url} />
                    ))}
                </Grid>
            }
        />
    );
};

function useOrderRefabFileUrls(orderId: string) {
    const { data: allChatMessagesData } = useListChatMessagesQuery({
        variables: {
            entity_ids: [orderId],
        },
        skip: !UuidUtils.isUUID(orderId),
    });

    // Memo-izing to avoid redundant firebase queries.
    return React.useMemo(() => {
        if (!allChatMessagesData?.listChatMessages) {
            return [];
        }

        const refabMessages = allChatMessagesData.listChatMessages.filter(
            msg =>
                msg.text === 'Refabrication files' && msg.sender.organization_type === LabsGqlOrganizationType.Practice,
        );
        return refabMessages.flatMap(msg => msg.attachments?.map(a => a.url) ?? []);
    }, [allChatMessagesData]);
}

const OrderDetailRefabAnnotations: React.VFC<OrderDetailRefabFilesProps> = ({
    urls: annotationUrls,
    orderId,
    showDivider,
}) => {
    // Loads firebase URLs since we currently just have Firebase paths in the annotations list.
    const { result: previews, loading } = useFirebasePreviewMulti(
        annotationUrls.map(url => ({ source: url, name: url })),
    );
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    return (
        <RefabBlockSectionRow
            section={'Annotations'}
            showDivider={showDivider}
            content={
                <Grid>
                    {selectedAttachmentPreview && (
                        <DandyLightbox
                            setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                            loading={loading}
                            previews={previews}
                            selectedAttachmentPreview={selectedAttachmentPreview}
                            onPhotoViewedAnalytics={(source, name) => {
                                return {
                                    name: 'All - Portal - Photo Lightbox Viewed',
                                    data: {
                                        $groups: { order: orderId },
                                        displayLocation: 'order_refab_block',
                                        photoSource: source,
                                        photoName: name,
                                    },
                                };
                            }}
                        />
                    )}
                    {annotationUrls.map((url, idx) => (
                        <OrderDetailRefabAnnotation
                            key={`refab_annotation_${idx}`}
                            url={url}
                            previewUrl={previews?.[idx]?.source}
                            onPreviewEnlarged={() => setSelectedAttachmentPreview(previews?.[idx]?.name)}
                        />
                    ))}
                </Grid>
            }
        />
    );
};

interface OrderDetailsRefabsProps {
    order: Pick<LabsGqlOrder, 'id' | 'items_v2' | 'original_order_id' | 'scan_export_id' | 'notes_for_refabrication'>;
    openOrder?: (id: string) => void;
    extraActions?: SimpleMenuPropsItem[];
    refetchOrder?: () => Promise<void>;
    showRefabEditChips?: boolean;
    hideBlockWrapper?: boolean;
}

interface OrderDetailsRefabsInternalProps extends OrderDetailsRefabsProps {
    originalOrder?: Pick<LabsGqlOrder, 'items_v2' | 'refabrication_notes' | 'scan_export_id' | 'refab_reasons'>;
}

const OrderDetailRefabBlockInternal: React.VFC<OrderDetailsRefabsInternalProps> = ({
    order,
    originalOrder,
    openOrder,
    extraActions,
    refetchOrder,
    showRefabEditChips,
    hideBlockWrapper,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const { original_order_id } = order;
    const openOriginalOrder = React.useCallback(
        () => openOrder?.(original_order_id ?? ''),
        [openOrder, original_order_id],
    );

    const { data } = useGetReviewQuery({
        // Defaulting to '' just to please TS but we'll skip the query if there is no original order id.
        variables: { order_id: original_order_id ?? '' },
        // If there is no original order id, we won't render this component so we just skip the query.
        skip: !original_order_id,
    });

    // refab reason codes and file attachments are stored in the review submission created off the original order
    const originalOrderReview = data?.getReview;
    // Refab attachments are stored on ReviewSubmissionDTO with other refab data now, but to keep compatibility
    // with refabs that were submitted with the old flow, we continue to check chat messages for refab files
    const chatMessageRefabFileUrls = useOrderRefabFileUrls(order.id);
    // We flatten all item attachment urls to display them along with order attachments
    const itemFileUrls =
        originalOrderReview?.items.flatMap(item => item.item_attachments ?? []).map(attachment => attachment.url) ?? [];
    const orderFileUrls = originalOrderReview?.attachments.map(attachment => attachment.url) ?? [];
    const reviewAttachmentUrls = [...orderFileUrls, ...itemFileUrls];
    const refabFileUrls = reviewAttachmentUrls.length ? reviewAttachmentUrls : chatMessageRefabFileUrls;
    const annotationUrls = originalOrderReview?.annotations.map(annotation => annotation.image_url) ?? [];
    const scanChanged = originalOrder && originalOrder.scan_export_id !== order.scan_export_id;

    const refabDetailItems = React.useMemo(() => {
        const reviewItems = originalOrderReview?.items ?? [];
        const refabbedItems = reviewItems
            .filter(reviewItem =>
                original_order_id
                    ? originalOrder?.items_v2.find(orderItem => orderItem.id === reviewItem.item_id)
                    : order.items_v2.find(orderItem => orderItem.id === reviewItem.item_id),
            )
            .filter(reviewItem => !!reviewItem.is_refab);

        const convertedItems = {
            originalItems: OrderItemV2Utils.parseItems(originalOrder?.items_v2 ?? []),
            newItems: OrderItemV2Utils.parseItems(order.items_v2),
        };

        const rawItems = refabbedItems.map(reviewItem => {
            const orderItem = original_order_id
                ? originalOrder?.items_v2.find(orderItem => orderItem.id === reviewItem.item_id)
                : order.items_v2.find(orderItem => orderItem.id === reviewItem.item_id);

            const convertedOrderItem = orderItem ? OrderItemV2Utils.parseItem(orderItem) : undefined;

            if (!convertedOrderItem) {
                return undefined;
            }

            const hasShadeUpdates = reviewItem.tags.some(t => isShadeReasonByCategoryName(t.category));
            return {
                item: convertedOrderItem,
                tags: reviewItem.tags,
                shadeUpdates: hasShadeUpdates
                    ? getFormattedShadeUpdates(orderItem?.id, convertedItems.originalItems, convertedItems.newItems)
                    : [],
            };
        });
        return _.compact(rawItems);
    }, [originalOrderReview, originalOrder?.items_v2, order.items_v2, original_order_id]);

    if (!original_order_id) {
        return null;
    }

    const internalContents = (
        <Grid container direction={'column'}>
            {/* Items display */}
            {refabDetailItems && (
                <Grid item>
                    {refabDetailItems.map((detailItem, idx) => (
                        <OrderDetailRefabBlockItem
                            key={`detail_item_${idx}`}
                            orderId={original_order_id}
                            item={detailItem.item}
                            tags={detailItem.tags}
                            showDivider={
                                idx !== refabDetailItems.length - 1 ||
                                !!originalOrder?.refabrication_notes ||
                                !!originalOrder?.refab_reasons?.length ||
                                !!annotationUrls.length ||
                                !!refabFileUrls.length
                            }
                            showRefabEditChips={showRefabEditChips}
                            refabShadeUpdates={detailItem.shadeUpdates}
                        />
                    ))}
                </Grid>
            )}
            {/* (Legacy) Order-level refab reasons display */}
            {!!originalOrder?.refab_reasons?.length && (
                <RefabBlockSectionRow
                    section={`Refab Reason`}
                    content={originalOrder.refab_reasons.map(r => r.label).join(', ')}
                    showDivider={!!originalOrder?.refabrication_notes}
                />
            )}
            {/* Order-level refab notes display */}
            <RefabBlockSectionRow
                section={`General notes`}
                content={
                    <>
                        <span style={{ whiteSpace: 'pre-line' }}>{order.notes_for_refabrication || 'None'}</span>
                        <RefabGeneralNotesEditorChip
                            childProps={{
                                order: { id: order.id },
                                deleteNoteContent: false,
                                savedNotesForRefab: order.notes_for_refabrication || '',
                                showRefabEditChips: showRefabEditChips,
                            }}
                            refetch={refetchOrder}
                            open={open}
                            setOpen={setOpen}
                        />
                    </>
                }
                showDivider={!!annotationUrls.length || !!refabFileUrls.length}
            />
            {/* Annotated screenshots display */}
            {!!annotationUrls.length && (
                <OrderDetailRefabAnnotations
                    urls={annotationUrls}
                    orderId={order.id}
                    showDivider={!!refabFileUrls.length}
                />
            )}
            {!!refabFileUrls.length && <OrderDetailRefabFiles urls={refabFileUrls} orderId={order.id} />}
        </Grid>
    );

    if (hideBlockWrapper) {
        return internalContents;
    }

    return (
        <OrderDetailBlock
            title={<RefabTitle label={`This is a refabrication order${scanChanged ? ' [NEW SCANS]' : ''}`} />}
            variant={'full'}
            buttonsBelowOnMobile
            actions={
                extraActions ? (
                    <SimpleMenu
                        ButtonComponent={({ onClick }) => (
                            <IconButton onClick={onClick}>
                                <MoreVertIcon />
                            </IconButton>
                        )}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        }}
                        items={[
                            ...(openOrder
                                ? [
                                      {
                                          label: 'View original order',
                                          onClick: openOriginalOrder,
                                      },
                                  ]
                                : []),
                            ...(extraActions ?? []),
                        ]}
                    />
                ) : (
                    <Button variant={'ghost'} onClick={openOriginalOrder}>
                        View original order
                    </Button>
                )
            }
        >
            {internalContents}
        </OrderDetailBlock>
    );
};

export const OrderDetailRefabBlock: React.VFC<OrderDetailsRefabsProps> = props => {
    const { order: originalOrder } = useOrder(props.order.original_order_id ?? ``, {
        skip: !props.order.original_order_id,
    });

    return <OrderDetailRefabBlockInternal {...props} originalOrder={originalOrder} />;
};
