import { useLazyQuery } from '@apollo/client';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { apolloErrorMessage } from '@orthly/ui';
import { useSnackbar } from 'notistack';

export interface ScannedOrderDetails {
    orderId: string;
    orderNumber: string;
    patientName: string;
}

const LabPortalScannedOrderDetails_Fragment = graphql(`
    fragment LabPortalScannedOrderDetails_Fragment on SalesOrderDTO {
        id
        order_number
        patient {
            first_name
            last_name
        }
    }
`);

const LabPortalScannedOrderDetails_Query = graphql(`
    query LabPortalScannedOrderDetails_Query($order_id: String!) {
        salesOrderGetById(id: $order_id) {
            ...LabPortalScannedOrderDetails_Fragment
        }
    }
`);

export function useScannedOrderDetailsQuery(
    scannedOrders: ScannedOrderDetails[],
    setScannedOrders: (orders: ScannedOrderDetails[]) => void,
) {
    const { enqueueSnackbar } = useSnackbar();

    return useLazyQuery(LabPortalScannedOrderDetails_Query, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onError: error => {
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
        },
        onCompleted: data => {
            const orderRaw = getFragmentData(LabPortalScannedOrderDetails_Fragment, data?.salesOrderGetById) ?? null;
            if (!orderRaw) {
                enqueueSnackbar('Error fetching details about this order', { variant: 'error' });
                return;
            }
            const order = {
                orderNumber: orderRaw.order_number,
                orderId: orderRaw.id,
                patientName: `${orderRaw.patient.first_name} ${orderRaw.patient.last_name}`,
            };

            setScannedOrders([order, ...scannedOrders]);
        },
    });
}
