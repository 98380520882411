import { MUITableUtils } from '../../../contexts/MUITableUtils';
import { useTableData } from '../../../contexts/TableDataContext';
import { useTranslationOptionsContext } from '../../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../../state';
import type { ColumnFilterMod } from '../../../state/TableStateContext';
import { useTableStateAction } from '../../../state/action-hooks';
import type { Column, MUITableFilterType } from '../../../types';
import { MUITableDateFilter } from './MUITableDateFilter';
import { useFilterClasses } from './useFilterClasses';
import type { SelectChangeEvent } from '@orthly/ui-primitives';
import {
    Button,
    CheckboxPrimitive as Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Text,
} from '@orthly/ui-primitives';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

export interface RenderFilterProp {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    column: Column<any>;
    currentValues: string[];
    filterMod: ColumnFilterMod;
    options: string[];
}

const MUITableMultiSelectFilter = (props: RenderFilterProp) => {
    const classes = useFilterClasses();
    const { column, currentValues, options } = props;
    const onFilterUpdate = useTableStateAction('updateFilterValues');
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMultiSelectChange = (e: any) => {
        onFilterUpdate(column.name, e.target.value);
    };

    return (
        <div className={classes.selectRoot} data-testid={'MUITableMultiSelectFilter'}>
            <FormControl variant={'standard'} className={classes.selectFormControl}>
                <InputLabel htmlFor={column.name}>{column.title || column.name}</InputLabel>
                <Select
                    multiple
                    value={currentValues}
                    renderValue={selected => (
                        <span data-testid={'MUITableMultiSelectFilterVal'}>
                            {
                                // EPDPLT-4736: Using any is unsafe and should be avoided.
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                selected ? (selected as any).toString() : null
                            }
                        </span>
                    )}
                    displayEmpty
                    name={column.name}
                    onChange={handleMultiSelectChange}
                    input={<Input name={column.name} id={column.name} />}
                    variant={'standard'}
                >
                    {options.map((filterValue, filterIndex) => (
                        <MenuItem value={filterValue} key={filterIndex + 1}>
                            <Checkbox
                                color={'secondary'}
                                checked={currentValues.indexOf(filterValue) >= 0}
                                value={filterValue.toString()}
                                className={classes.checkboxIcon}
                                classes={{
                                    root: classes.checkbox,
                                    checked: classes.checked,
                                }}
                            />
                            <ListItemText primary={filterValue} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export const MUITableSelectFilter = (props: RenderFilterProp) => {
    const classes = useFilterClasses();
    const { column, currentValues, options } = props;
    const translations = useTranslationOptionsContext();
    const onFilterUpdate = useTableStateAction('updateFilterValues');
    const handleDropdownChange = (event: SelectChangeEvent<string>) => {
        const filterValue = typeof event.target.value === 'string' ? [event.target.value] : [];
        onFilterUpdate(column.name, filterValue);
    };
    const textLabels = translations.filter;
    return (
        <div className={classes.selectRoot}>
            <FormControl
                variant={'standard'}
                className={classes.selectFormControl}
                data-testid={'MUITableSelectFilter'}
            >
                <InputLabel htmlFor={column.name}>{column.title || column.name}</InputLabel>
                <Select
                    value={currentValues.length > 0 ? currentValues.join(',') : ''}
                    name={typeof column.title === 'string' ? column.title : column.name}
                    onChange={handleDropdownChange}
                    input={<Input name={column.name} id={column.name} />}
                    renderValue={value => <span data-testid={'MUITableSelectFilterVal'}>{value as string}</span>}
                    variant={'standard'}
                >
                    <MenuItem value={''}>
                        <em>{textLabels.all}</em>
                    </MenuItem>
                    {options.map((filterValue, filterIndex) => (
                        <MenuItem value={filterValue} key={filterIndex + 1}>
                            {filterValue.toString()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export const MUITableCheckBoxFilter = (props: RenderFilterProp) => {
    const classes = useFilterClasses();
    const onFilterUpdate = useTableStateAction('updateFilterValues');
    const { column, currentValues, options } = props;
    const handleCheckboxChange = (value: string) => () => {
        if (currentValues.includes(value)) {
            return onFilterUpdate(
                column.name,
                currentValues.filter(v => v !== value),
            );
        }
        return onFilterUpdate(column.name, [...currentValues, value]);
    };

    return (
        <div className={classes.checkboxList} data-testid={'MUITableCheckBoxFilter'}>
            <FormGroup>
                <Text variant={'body2'} className={classes.checkboxListTitle}>
                    {column.title || column.name}
                </Text>
                {options.map((filterValue, filterIndex) => (
                    <FormControlLabel
                        key={filterIndex}
                        classes={{
                            root: classes.checkboxFormControl,
                            label: classes.checkboxFormControlLabel,
                        }}
                        data-testid={'MUITableCheckBoxFilterVal'}
                        control={
                            <Checkbox
                                color={'secondary'}
                                className={classes.checkboxIcon}
                                onChange={handleCheckboxChange(filterValue)}
                                checked={currentValues.indexOf(filterValue) >= 0}
                                classes={{
                                    root: classes.checkbox,
                                    checked: classes.checked,
                                }}
                                value={filterValue}
                            />
                        }
                        label={filterValue}
                    />
                ))}
            </FormGroup>
        </div>
    );
};

const MUITableSearchFilter = (props: RenderFilterProp) => {
    const classes = useFilterClasses();
    const onFilterUpdate = useTableStateAction('updateFilterValues');
    const { column, currentValues } = props;
    const handleDropdownChange = _.debounce((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const filterValue = typeof event.target.value === 'string' ? [event.target.value] : [];
        onFilterUpdate(column.name, filterValue);
    });
    const currentValue = currentValues[0] ?? '';

    return (
        <div className={classes.selectRoot} data-testid={'MUITableSearchFilter'}>
            <TextField
                label={column.title || _.startCase(column.name)}
                onChange={e => {
                    e.persist();
                    handleDropdownChange(e);
                }}
                defaultValue={currentValue}
                variant={'standard'}
            />
        </div>
    );
};

export const MUITableFilter = () => {
    const classes = useFilterClasses();
    const columnFilters = useMuiTableSelector(s => s.columnFilters);
    const onFilterReset = useTableStateAction('onFilterReset');
    const translations = useTranslationOptionsContext();
    const { columns, getFilterOptions } = useTableData();
    const textLabels = translations.filter;
    const fieldProps = React.useMemo(() => {
        return _.compact(
            columns.map<(RenderFilterProp & { type: MUITableFilterType }) | undefined>(column => {
                const filterType = MUITableUtils.filterTypeForColumn(column);
                if (!filterType) {
                    return undefined;
                }
                const options = getFilterOptions(column);
                const optionsEmpty = options.length === 0 || (options.length === 1 && options[0] === '[object Object]');
                if (filterType !== 'date' && filterType !== 'search' && optionsEmpty) {
                    return undefined;
                }
                // Switch to search if there are a ton of options
                const type: MUITableFilterType =
                    !column.filterOptions?.type && options.length > 50 ? 'search' : filterType;
                const filterState = columnFilters.find(c => c.columnName === column.name);
                const currentValues = filterState?.filterValues || [];
                return { column, currentValues, options, type, filterMod: filterState?.mod ?? '=' };
            }),
        );
    }, [columnFilters, columns, getFilterOptions]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.reset}>
                    <Text
                        variant={'body2'}
                        className={classNames({
                            [classes.title]: true,
                        })}
                    >
                        {textLabels.title}
                    </Text>
                    <Button
                        className={classes.resetLink}
                        variant={'contained'}
                        tabIndex={0}
                        aria-label={textLabels.reset}
                        onClick={onFilterReset}
                    >
                        {textLabels.reset}
                    </Button>
                </div>
                <div className={classes.filtersSelected} />
            </div>
            {fieldProps.map(({ type, ...filterFieldProps }) => {
                const key = filterFieldProps.column.name;
                const filterProps: RenderFilterProp & { key: string } = { key, ...filterFieldProps };
                switch (type) {
                    case 'search':
                        return <MUITableSearchFilter {...filterProps} />;
                    case 'checkbox':
                        return <MUITableCheckBoxFilter {...filterProps} />;
                    case 'dropdown':
                        return <MUITableSelectFilter {...filterProps} />;
                    case 'date':
                        return <MUITableDateFilter {...filterProps} />;
                    case 'multiselect':
                        return <MUITableMultiSelectFilter {...filterProps} />;
                }
                return null;
            })}
        </div>
    );
};
