import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { type ScannedOrderDetails } from './useScannedOrderDetailsQuery.graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { apolloErrorMessage } from '@orthly/ui';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

export type ManifestBatchOrdersResult = {
    labelUrl: string;
    serviceLevel: string;
};

interface BatchOrderShipmentsAndRecordPackingCompletedProps {
    scannedOrders: ScannedOrderDetails[];
    setScannedOrders: (orders: ScannedOrderDetails[]) => void;
    refetchManifest: () => void;
    setBatchingResult: (result: ManifestBatchOrdersResult) => void;
}

const BatchOrderShipmentsAndRecordPackingCompletedMutation = graphql(`
    mutation BatchOrderShipmentsAndRecordPackingCompleted($labOrderIds: [String!]!) {
        batchOrderShipmentsAndRecordPackingCompleted(labOrderIds: $labOrderIds) {
            batching_result {
                label_url
                service_level
                tracking_number
                updated_order_count
            }
            packing_success
        }
    }
`);

export function useBatchOrderShipmentsAndRecordPackingCompleted({
    scannedOrders,
    setScannedOrders,
    refetchManifest,
    setBatchingResult,
}: BatchOrderShipmentsAndRecordPackingCompletedProps) {
    const { enqueueSnackbar } = useSnackbar();

    const [createBatchAndRecordPackingCompleteMtn, { loading }] = useMutation(
        //the fn to call inline
        BatchOrderShipmentsAndRecordPackingCompletedMutation, //doc
        {
            fetchPolicy: 'no-cache',
            onError: error => {
                enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
            },
            onCompleted: ({ batchOrderShipmentsAndRecordPackingCompleted: result }) => {
                void refetchManifest();
                setScannedOrders([]);

                // Should rarely, if ever, happen
                if (!result.packing_success) {
                    const message = `Batching likely succeeded, but we FAILED to mark orders as packing-complete. Please try again`;
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                setBatchingResult({
                    labelUrl: result.batching_result.label_url,
                    serviceLevel: result.batching_result.service_level,
                });
                const serviceLevel = _.startCase(result.batching_result.service_level);
                const message = `${scannedOrders.length} orders batched! Please print label: ${serviceLevel}`;
                enqueueSnackbar(message, { variant: 'success' });

                scannedOrders.forEach(order => {
                    AnalyticsClient.track('Lab - Portal - Order Added to Batch', {
                        $groups: { order: order.orderId },
                        trackingNumber: result.batching_result.tracking_number,
                    });
                });
            },
        },
    );

    const createBatchAndRecordPackingComplete = () => {
        if (loading) {
            enqueueSnackbar('Batching already in-progress');
        } else {
            void createBatchAndRecordPackingCompleteMtn({
                variables: { labOrderIds: scannedOrders.map(o => o.orderId) },
            });
        }
    };

    return { loading, createBatchAndRecordPackingComplete };
}
