import { useRowOptionsContext } from '../../contexts/TableOptionsContext';
import { useTableStateContext } from '../../state/TableStateContext';
import type { Row } from '../../types';
import type { TableRowProps } from '@orthly/ui-primitives';
import { createStyles, makeStyles, TableRow } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            root: {},
        }),
    { name: 'MUITableBodyRow' },
);

export interface MUITableBodyRowProps {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    row?: Row<any>;
    index: number;
    hover?: boolean;
    selected?: boolean;
    className?: string;
    testId?: string;
    children?: React.ReactNode;
    disableHover?: boolean;
    TableRowProps?: TableRowProps;
}

export const MUITableBodyRow: React.FC<MUITableBodyRowProps> = props => {
    const classes = useStyles();
    const { selected, className, testId, disableHover } = props;
    const hasOnClick = !!useTableStateContext().state.providedHooks.find(h => h === 'onRowClick');
    const rowOptions = useRowOptionsContext();
    const { setRowStyle } = rowOptions;
    const rowStyle = React.useMemo(() => {
        return props.row && !!setRowStyle ? setRowStyle(props.row, props.index) : undefined;
    }, [props.index, props.row, setRowStyle]);
    return (
        <TableRow
            hover={rowOptions.rowHover && !disableHover}
            data-testid={testId}
            className={classNames(classes.root, className)}
            selected={selected}
            style={{ cursor: hasOnClick && !!props.row ? 'pointer' : undefined, ...rowStyle }}
            {...props.TableRowProps}
        >
            {props.children}
        </TableRow>
    );
};
