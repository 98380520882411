import { useLabPortalOrderDetails } from '../../../labs/LabsUtils';
import { InnerOrderDetailRoot } from '../../../labs/order-detail-v2/OrderDetailRoot.graphql';
import { OrderDetailTimelineItemsProvider } from '../../../labs/order-detail-v2/OrderDetailTimelineItemsProvider';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { RootActionDialog } from '@orthly/ui';
import { IconButton, VisibilityIcon } from '@orthly/ui-primitives';
import moment from 'moment-timezone';
import React from 'react';

const LabPortalOrderDetailViewerRootSalesOrder_Query = graphql(`
    query LabPortalOrderDetailViewerRootSalesOrder_Query($id: String!) {
        salesOrderGetById(id: $id) {
            id
            created_at
        }
    }
`);

const OrderDetailViewerRoot: React.FC<{ orderId: string }> = ({ orderId }) => {
    const { order, refetch: refetchOrder, loading } = useLabPortalOrderDetails(orderId, { fetchPolicy: 'no-cache' });
    const { data: salesOrderData, refetch: refetchSalesOrder } = useQuery(
        LabPortalOrderDetailViewerRootSalesOrder_Query,
        {
            variables: { id: orderId },
        },
    );
    const refetch = async (variables: any) => {
        await refetchOrder(variables);
        await refetchSalesOrder(variables);
    };

    const salesOrderResult = salesOrderData?.salesOrderGetById;
    if (!salesOrderResult) {
        return null;
    }

    const salesOrder = {
        id: salesOrderResult.id,
        createdAt: moment(salesOrderResult.created_at).toDate(),
    };

    return (
        <OrderDetailTimelineItemsProvider order={order} orderId={orderId}>
            <InnerOrderDetailRoot
                order={order}
                salesOrder={salesOrder}
                refetch={refetch}
                loading={loading}
                disableBackButton={true}
            />
        </OrderDetailTimelineItemsProvider>
    );
};

export const OrderDetailViewerDialog: React.FC<{ order_id: string }> = props => {
    const { order_id } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const CustomButton = React.useCallback(
        () => (
            <IconButton onClick={() => setOpen(true)}>
                <VisibilityIcon />
            </IconButton>
        ),
        [],
    );
    return (
        <RootActionDialog
            showCloseButton
            loading={false}
            open={open}
            setOpen={setOpen}
            title={''}
            dialogProps={{ maxWidth: 'xl' }}
            titleProps={{ style: { display: 'none' } }}
            content={open ? <OrderDetailViewerRoot orderId={order_id} /> : null}
            buttonText={''}
            CustomButton={CustomButton}
        />
    );
};
