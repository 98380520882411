import { Button } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const PrintShippingLabelButton: React.VFC<{
    unprintedLabelUrl: string;
    setLabelWasOpened: (wasOpened: boolean) => void;
    serviceLevel: string;
}> = ({ unprintedLabelUrl, setLabelWasOpened, serviceLevel }) => {
    const printShippingLabel = () => {
        window.open(unprintedLabelUrl, '_blank', 'noreferrer noopener');
        setLabelWasOpened(true);
    };

    return (
        <Button variant={'primary'} onClick={() => printShippingLabel()}>
            Print {_.startCase(serviceLevel)} Shipping Label
        </Button>
    );
};
