import { useTableData } from '../../contexts/TableDataContext';
import { useTranslationOptionsContext } from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import { useTableStateAction } from '../../state/action-hooks';
import type { Theme } from '@orthly/ui-primitives';
import {
    createStyles,
    makeStyles,
    CheckboxPrimitive as Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            __docgenInfo: {},
            displayName: {},
            root: {
                padding: '16px 24px 16px 24px',
                fontFamily: 'Roboto',
                maxHeight: 500,
            },
            title: {
                marginLeft: '-7px',
                fontSize: '14px',
                color: theme.palette.text.secondary,
                textAlign: 'left' as 'left',
                fontWeight: 500,
            },
            formGroup: {
                marginTop: '8px',
            },
            formControl: {},
            checkbox: {
                padding: '0px',
                width: '32px',
                height: '32px',
            },
            checkboxRoot: {
                '&.Mui-checked': {
                    color: theme.palette.primary.main,
                },
            },
            checked: {},
            label: {
                fontSize: '15px',
                marginLeft: '8px',
                color: theme.palette.text.primary,
            },
        }),
    { name: 'MUITableViewCols' },
);

export const MUITableViewCols = () => {
    const classes = useStyles();
    const { columns } = useTableData();
    const hiddenColumnNames = useMuiTableSelector(s => s.hiddenColumnNames);
    const toggleViewColumn = useTableStateAction('toggleViewColumn');
    const translations = useTranslationOptionsContext();
    const textLabels = translations.viewColumns;
    return (
        <FormControl
            variant={'standard'}
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            component={'fieldset' as any}
            className={classes.root}
            aria-label={textLabels.titleAria}
        >
            <Text variant={'caption'} className={classes.title}>
                {textLabels.title}
            </Text>
            <FormGroup className={classes.formGroup}>
                {columns.map(column => (
                    <FormControlLabel
                        key={column.name}
                        classes={{ root: classes.formControl, label: classes.label }}
                        data-testid={'MUITableViewColsVal'}
                        control={
                            <Checkbox
                                color={'secondary'}
                                className={classes.checkbox}
                                classes={{ root: classes.checkboxRoot, checked: classes.checked }}
                                onChange={() => {
                                    toggleViewColumn(column.name);
                                }}
                                checked={!hiddenColumnNames.includes(column.name)}
                            />
                        }
                        label={column.title ? column.title : column.name}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};
