import type { EventHookOptions, MUIDataObj } from '../types';
import type { TableStateActions } from './TableStateActions';
import type { MUITableState } from './TableStateContext';

type ActionsClass = Omit<typeof TableStateActions, 'prototype'>;

type ActionPayload = {
    [K in keyof ActionsClass]: Omit<ReturnType<ActionsClass[K]>, 'type'> & { type: K };
}[keyof ActionsClass];

export const TableStateHooksMiddleware =
    <R extends MUIDataObj>(hooks: EventHookOptions<R>) =>
    (action: ActionPayload, result: MUITableState, actionDispatcher: TableStateActions): void => {
        try {
            switch (action.type) {
                case 'toggleViewColumn':
                    hooks.onColumnViewChange?.(action.payload, !result.hiddenColumnNames.includes(action.payload));
                    break;
                case 'searchTextUpdate':
                    hooks.onSearchChange?.(action.payload || '');
                    break;
                case 'changePage':
                    hooks.onChangePage?.(action.payload);
                    break;
                case 'changeRowsPerPage':
                    hooks.onRowsPerPageChange?.(action.payload);
                    break;
                case 'toggleSearchVisible':
                    break;
                case 'handleAllSelect':
                    hooks.onRowSelectionChange?.(result.selectedRows);
                    break;
                case 'onFilterReset':
                    break;
                case 'updateFilterValues':
                    hooks.onFilterChange?.(action.payload);
                    break;
                case 'toggleDetailPanel':
                    hooks.onOpenDetailPanelsChange?.([]);
                    break;
                case 'toggleRowSelected':
                    hooks.onRowSelectionChange?.(result.selectedRows);
                    break;
                case 'toggleSort':
                    hooks.onColumnSortChange?.(
                        action.payload,
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        result.sortColumn ? (result.sortColumn.asc ? 'asc' : 'desc') : null,
                    );
                    break;
                case 'onCellClick':
                    hooks.onCellClick?.(action.payload.row, action.payload.column, actionDispatcher);
                    hooks.onRowClick?.(action.payload.row, actionDispatcher);
                    break;
            }
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            // eslint-disable-next-line @orthly/no-console-log
            console.error(e);
        }
    };
