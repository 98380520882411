import { Text, Card, styled, FlossPalette, Icon, IconButton } from '@orthly/ui-primitives';
import { type CustomContentProps, useSnackbar } from 'notistack';
import { SnackbarContent } from 'notistack';
import React from 'react';

const StyledCard = styled(Card)({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: `
        "icon message close"
        ". reminder ."
        ". error_id ."`,
    columnGap: '8px',
    padding: '8px',
    borderLeft: `4px solid ${FlossPalette.ATTENTION}`,
    background: FlossPalette.ATTENTION_BG,
});

interface ErrorToastProps extends CustomContentProps {
    submessage?: string;
    fatal?: boolean;
}

export const ErrorToast = React.forwardRef<HTMLDivElement, ErrorToastProps>(
    (
        {
            id,
            message,
            submessage,
            persist: __persist,
            anchorOrigin: __anchorOrigin,
            iconVariant: __iconVariant,
            hideIconVariant: __hideIconVariant,
            autoHideDuration: __autoHideDuration,
            fatal,
            ...other
        },
        ref,
    ) => {
        const { closeSnackbar } = useSnackbar();

        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fullStorySessionId = (window as any)?.FS?.getCurrentSession?.();

        return (
            <SnackbarContent ref={ref} role={'alert'} {...other}>
                <StyledCard>
                    <Icon icon={'WarningIcon'} sx={{ color: 'red', gridArea: 'icon' }} />
                    <Text variant={'body2'} bold color={'ATTENTION'} sx={{ fontWeight: 'bold', gridArea: 'message' }}>
                        {message}
                    </Text>
                    {submessage && (
                        <Text variant={'body2'} sx={{ gridArea: 'reminder' }}>
                            {submessage}
                        </Text>
                    )}
                    {!fatal && !submessage && (
                        <Text variant={'body2'} sx={{ gridArea: 'reminder' }}>
                            Please try again. If this persists, please contact support.
                        </Text>
                    )}
                    {fullStorySessionId && (
                        <Text variant={'caption'} color={'GRAY'} sx={{ marginTop: '16px', gridArea: 'error_id' }}>
                            Error ID: {fullStorySessionId}
                        </Text>
                    )}
                    <IconButton onClick={() => closeSnackbar(id)} sx={{ gridArea: 'close' }}>
                        <Icon icon={'CloseIcon'} />
                    </IconButton>
                </StyledCard>
            </SnackbarContent>
        );
    },
);

declare module 'notistack' {
    interface VariantOverrides {
        error: {
            fatal?: boolean;
        };
    }
}
