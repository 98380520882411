import { useBeforeUnload } from '../../hooks/useBeforeUnload';
import { LoadBlocker } from '../Loaders';
import { OrthlyErrorBoundary } from '../OrthlyErrorBoundary';
import type { FieldsDefProp, PartialObjectDeep } from './QuickForm.types';
import { buildQFInitialValues, buildQFValidation } from './QuickForm.utils';
import { QuickFormFieldList } from './QuickFormFieldList';
import { toFormikValidationSchema } from './zod-formik-adapter';
import type { ButtonProps } from '@orthly/ui-primitives';
import { Button, Grid } from '@orthly/ui-primitives';
import * as Sentry from '@sentry/react';
import type { FormikActions, FormikProps } from 'formik';
import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Prompt } from 'react-router-dom';

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObj = { [k: string]: any };

type ButtonPropsWithoutVariant = Omit<ButtonProps, 'variant'>;

interface QuickFormRenderProps<R extends AnyObj> {
    rootProps: QuickFormProps<R>;
    formikProps: FormikProps<Partial<R>>;
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSubmitDisabled(rootProps: QuickFormProps<any>, formikProps: FormikProps<Partial<AnyObj>>) {
    if (rootProps.disabled === true) {
        return true;
    }
    if (formikProps.isSubmitting) {
        return true;
    }
    if (rootProps.dirtySubmitOnly && !formikProps.dirty) {
        return true;
    }
    return !formikProps.isValid;
}

function QuickFormRender<R extends AnyObj>({ rootProps, formikProps }: QuickFormRenderProps<R>) {
    const {
        values: formValues,
        resetForm,
        initialValues: formikInitValues,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        touched,
    } = formikProps;
    const { onChange, onRender, CustomSubmit, CustomLayout } = rootProps;
    const [lastOnChangeTrigger, setLastOnChangeTrigger] = React.useState<Partial<R>>(formValues);
    React.useEffect(() => {
        try {
            if (!_.isEqual(formValues, lastOnChangeTrigger) && onChange) {
                setLastOnChangeTrigger(formValues);
                onChange(formValues, { setFieldValue, setFieldTouched, setFieldError, touched });
            }
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.error(e);
        }
    }, [formValues, lastOnChangeTrigger, onChange, setFieldError, setFieldValue, setFieldTouched, touched]);
    const initialChange = !_.isEqual(rootProps.initialValues, formikInitValues);
    React.useEffect(() => {
        if (rootProps.resetOnInitialValueChange && initialChange) {
            resetForm(rootProps.initialValues);
        }
    }, [rootProps.initialValues, rootProps.resetOnInitialValueChange, initialChange, resetForm]);
    const disabled = isSubmitDisabled(rootProps, formikProps);
    const renderProps = React.useMemo<QuickFormCustomRenderProps<R>>(() => {
        return {
            formikProps,
            disabled,
            fieldList: <QuickFormFieldList fields={rootProps.fields} />,
            triggerSubmit: formikProps.submitForm,
            submitting: formikProps.isSubmitting,
        };
    }, [formikProps, disabled, rootProps.fields]);

    React.useEffect(() => {
        if (onRender !== undefined) {
            onRender(renderProps);
        }
    }, [onRender, renderProps]);

    useBeforeUnload(event => formikProps.dirty && event.preventDefault(), !!rootProps.preventDirtyExitMessage);

    if (CustomLayout) {
        return <CustomLayout {...renderProps} />;
    }
    const readOnlyBlocking = !formikProps.isSubmitting && !!rootProps.readOnly;
    const submitBtn = (
        <Button
            {...rootProps.submitButtonProps}
            onClick={async () => {
                await formikProps.validateForm();
                formikProps.submitForm();
            }}
            disabled={disabled}
            fullWidth
            variant={'primary'}
            style={{ marginTop: 10, ...rootProps.submitButtonProps?.style }}
            data-test={'quick-form-submit'}
        >
            {rootProps.submitButtonProps?.children ?? rootProps.submitButtonTitle ?? 'Submit Form'}
        </Button>
    );
    return (
        <Grid container justifyContent={'space-around'} style={rootProps.containerStyle}>
            {!!rootProps.preventDirtyExitMessage && (
                <Prompt when={formikProps.dirty} message={rootProps.preventDirtyExitMessage} />
            )}
            <LoadBlocker
                blocking={formikProps.isSubmitting ? true : !!rootProps.readOnly}
                loader={readOnlyBlocking ? <span>{null}</span> : 'circular'}
                overlayColor={readOnlyBlocking ? 'transparent' : undefined}
                LoaderContainerProps={{
                    style: { cursor: readOnlyBlocking ? 'default' : undefined },
                }}
            >
                <QuickFormFieldList fields={rootProps.fields} fieldListStyle={rootProps.fieldListStyle} />
                {rootProps.children}
                {CustomSubmit ? (
                    <CustomSubmit disabled={disabled} triggerSubmit={formikProps.submitForm} submitButton={submitBtn} />
                ) : (
                    <>{submitBtn}</>
                )}
            </LoadBlocker>
        </Grid>
    );
}

export interface QuickFormCustomSubmitProps {
    disabled: boolean;
    triggerSubmit: () => void;
    submitButton: React.ReactNode;
}

export interface QuickFormCustomRenderProps<R extends AnyObj> {
    disabled: boolean;
    triggerSubmit: () => void;
    submitting: boolean;
    fieldList: React.ReactNode;
    formikProps: FormikProps<Partial<R>>;
}

export type OnChangeFormikParam<R extends AnyObj> = Pick<
    FormikProps<Partial<R>>,
    'setFieldValue' | 'setFieldError' | 'setFieldTouched' | 'touched'
>;

export interface QuickFormProps<R extends AnyObj> {
    fields: FieldsDefProp<R>;
    initialValues: Partial<R>;
    onSubmit: (result: R, actions: FormikActions<R>) => unknown | Promise<unknown>;
    containerStyle?: React.CSSProperties;
    submitButtonProps?: ButtonPropsWithoutVariant;
    submitButtonTitle?: string;
    onChange?: (formValues: Readonly<PartialObjectDeep<R>>, formikProps: OnChangeFormikParam<R>) => void;
    resetOnInitialValueChange?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    // disable submit button if no form values have changed
    dirtySubmitOnly?: boolean;
    onError?: (error: Error) => void;
    children?: React.ReactNode;
    CustomSubmit?: React.ComponentType<QuickFormCustomSubmitProps>;
    CustomLayout?: React.ComponentType<QuickFormCustomRenderProps<R>>;
    onRender?: (props: QuickFormCustomRenderProps<R>) => void;
    fieldListStyle?: React.CSSProperties;
    preventDirtyExitMessage?: string;
}

function QuickFormInternal<Result extends AnyObj>(props: QuickFormProps<Result>) {
    const validationSchema = React.useMemo(() => {
        return buildQFValidation<Result>(props.fields);
    }, [props.fields]);
    const initialValues = buildQFInitialValues<Result>(props.fields, props.initialValues);
    const isInitialValid = React.useMemo(
        () => validationSchema.safeParse(initialValues).success,
        [initialValues, validationSchema],
    );
    return (
        <Formik<Partial<Result>>
            validationSchema={toFormikValidationSchema(validationSchema, {
                errorMap: (_data, ctx) => {
                    // Change the message for empty fields from 'Required' to 'This field is required'.
                    return { message: ctx.data === undefined ? `This field is required` : ctx.defaultError };
                },
            })}
            initialValues={initialValues}
            isInitialValid={isInitialValid}
            onSubmit={async (values, actions) => {
                try {
                    actions.setSubmitting(true);
                    // EPDPLT-4736: Using any is unsafe and should be avoided.
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    await props.onSubmit(validationSchema.parse(values), actions as any);
                    // EPDPLT-4736: Using any is unsafe and should be avoided.
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (e: any) {
                    Sentry.getCurrentScope().setContext('form_result', values);
                    Sentry.captureException(e);
                    props.onError && props.onError(e);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={formikProps => <QuickFormRender<Result> rootProps={props} formikProps={formikProps} />}
        />
    );
}

export function QuickForm<Result extends AnyObj>(props: QuickFormProps<Result>) {
    // Use an error boundary because it is possible to construct a invalid values
    // using variable property names due to what is probably a bug in TypeScript.
    // This isn't specific to QuickForm, it can happen anywhere, but it bit us here
    // so we're creating an error boundary.
    return (
        <OrthlyErrorBoundary>
            <QuickFormInternal {...props} />
        </OrthlyErrorBoundary>
    );
}
