// Shamelessly stolen from: https://github.com/donavon/use-interval/blob/master/src/index.tsx
import React from 'react';

type Delay = number | null;
// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TimerHandler = (...args: any[]) => void;

/**
 * Provides a declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */

export const useInterval = (callback: TimerHandler, delay: Delay) => {
    const savedCallbackRef = React.useRef<TimerHandler>(callback);

    React.useEffect(() => {
        savedCallbackRef.current = callback;
    }, [callback]);

    React.useEffect(() => {
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handler = (...args: any[]) => savedCallbackRef.current(...args);

        if (delay !== null) {
            const intervalId = setInterval(handler, delay);
            return () => clearInterval(intervalId);
        }
    }, [delay]);
};
