import type { FlossInputConfig } from '../primitives/Input/FlossInput';
import { FlossInputThemeProvider } from '../primitives/Input/FlossInput';
import type { TextFieldProps, AutocompleteProps, FilterOptionsState } from '@orthly/ui-primitives';
import {
    stylesFactory,
    Divider,
    createStyles,
    TextField,
    Autocomplete,
    FlossPalette,
    Text,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export type SimpleAutocompleteOption = { value: string | number; label?: string; subtitle?: string };

export interface SimpleAutocompleteProps extends FlossInputConfig {
    label: string;
    freeSolo?: boolean;
    options: SimpleAutocompleteOption[] | string[];
    onChange?: (value: string | null) => void;
    onInputChange?: (value: string | null) => void;
    disabled?: boolean;
    error?: string | boolean;
    helperText?: string;
    variant?: 'standard' | 'filled' | 'outlined';
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AutocompleteProps?: Partial<AutocompleteProps<any, any, any, any> & { 'data-test'?: string }>;
    TextFieldProps?: Partial<TextFieldProps & { 'data-test'?: string }>;
    initialInputValue?: string | null;
    autoFocus?: boolean;
}

function getInitialInputLabel(initialInputValue: string, options: SimpleAutocompleteOption[]): string {
    return options.find(o => o.value === initialInputValue)?.label ?? initialInputValue;
}

export const simpleAutocompleteOptionsFilter = (
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentOptionsRaw: any[],
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { inputValue }: FilterOptionsState<any>,
): SimpleAutocompleteOption[] => {
    if (inputValue.length === 0) {
        return currentOptionsRaw;
    }
    const currentOpts = currentOptionsRaw as SimpleAutocompleteOption[];
    return currentOpts.filter(opt => {
        const optValue = (opt.label ? `${opt.value} ${opt.label}` : `${opt.value}`).toLowerCase();
        const inputEls = inputValue.split(' ').map(el => el.toLowerCase().trim());
        return _.every(inputEls, e => optValue.includes(e));
    });
};

function isStringArray(list: SimpleAutocompleteOption[] | string[]): list is string[] {
    return list.length === 0 || typeof list[0] === 'string';
}

const useStyles = stylesFactory(() =>
    createStyles({
        group: {
            color: `${FlossPalette.GRAY} !important`, // NOTE: I hate using !important but material ui likes to override things
        },
        children: {
            color: `${FlossPalette.BLACK} !important`,
            marginLeft: 0,
            paddingLeft: 0,
            '& > li': {
                paddingLeft: '0px !important',
            },
        },
        divider: {
            marginTop: 7.5,
            marginBottom: 7.5,
            background: FlossPalette.DARK_TAN,
        },
    }),
);

export const SimpleAutocomplete: React.FC<SimpleAutocompleteProps> = props => {
    const { initialInputValue, options: rawOptions } = props;
    const options = isStringArray(rawOptions)
        ? rawOptions.map(option => ({
              label: option,
              value: option,
          }))
        : rawOptions;
    const [inputValue, setInputValue] = React.useState<string>(initialInputValue ?? '');
    const classes = useStyles();

    return (
        <FlossInputThemeProvider flossInputConfig={props.flossInputConfig}>
            <Autocomplete
                inputValue={inputValue}
                openOnFocus
                defaultValue={
                    initialInputValue
                        ? { value: initialInputValue, label: getInitialInputLabel(initialInputValue, options) }
                        : undefined
                }
                onChange={(__event, value) => {
                    props.onChange && props.onChange(value ? value.value : null);
                }}
                onInputChange={(__event, value) => {
                    props.onInputChange && props.onInputChange(value);
                    setInputValue(value);
                }}
                freeSolo={props.freeSolo}
                options={options}
                disabled={props.disabled}
                getOptionLabel={option => (typeof option === 'string' ? option : option.label || option.value)}
                renderGroup={option => {
                    return (
                        <div>
                            <Text variant={'body2'} className={classes.group}>
                                {option.key}
                            </Text>
                            <Text variant={'body2'} className={classes.children}>
                                {option.children}
                            </Text>
                            <Divider variant={'fullWidth'} className={classes.divider} />
                        </div>
                    );
                }}
                renderOption={(props, option) => {
                    let renderedOption = option.label ?? option.value;
                    if (option.subtitle) {
                        renderedOption = (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>{option.label ?? option.value}</div>
                                <div style={{ color: FlossPalette.GRAY }}>{option.subtitle}</div>
                            </div>
                        );
                    }
                    const key = `${option.value}${option.name}`;
                    return (
                        <li {...props} key={key}>
                            {renderedOption}
                        </li>
                    );
                }}
                filterOptions={simpleAutocompleteOptionsFilter}
                ListboxProps={{ style: { padding: 8 } }}
                renderInput={params => (
                    <TextField
                        {...params}
                        fullWidth
                        autoFocus={props.autoFocus}
                        style={{ margin: 0, fontSize: 14 }}
                        label={props.label}
                        margin={'normal'}
                        error={!!props.error}
                        variant={props.variant || 'standard'}
                        helperText={(typeof props.error === 'string' ? props.error : undefined) || props.helperText}
                        {...props.TextFieldProps}
                        InputProps={{
                            ...params.InputProps,
                            ...props.TextFieldProps?.InputProps,
                            startAdornment: props.TextFieldProps?.InputProps?.startAdornment,
                        }}
                        inputProps={{
                            ...params.inputProps,
                            ...props.TextFieldProps?.inputProps,
                        }}
                    />
                )}
                {...props.AutocompleteProps}
            />
        </FlossInputThemeProvider>
    );
};
SimpleAutocomplete.displayName = 'SimpleAutocomplete';
