import type { CurrencyFieldProps } from '../../declarations';
import type {
    SimpleDatePickProps,
    SimpleDateTimePickProps,
    SimpleMultiSelectProps,
    SimpleSelectOption,
    SimpleTimePickerProps,
} from '../SimpleForm';
import type { SimpleAutocompleteProps } from '../SimpleForm/SimpleAutocomplete';
import { CurrencyTextField } from './fields/CurrencyTextField/CurrencyTextField';
import type { CheckboxWithLabelProps, SelectProps, TextFieldProps } from './formik-mui';
import { CheckboxWithLabel, Select, TextField } from './formik-mui';
import type { InputLabelProps, GridSize } from '@orthly/ui-primitives';
import type { FieldConfig, FieldProps as FormikFieldCompProps } from 'formik';
import { Field } from 'formik';
import React from 'react';
import type { PartialDeep } from 'type-fest';
import type { z } from 'zod';

export type PartialObjectDeep<ObjectType extends object> = {
    [KeyType in keyof ObjectType]?: PartialDeep<ObjectType[KeyType]>;
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObj = { [k: string]: any };

type FormikFieldProps<T> = Omit<Partial<FieldConfig>, 'component' | 'render'> & Omit<T, 'field' | 'form'>;

export function wrapFormikField<T>(component: React.ComponentType<T>) {
    const NewComp: React.FC<FormikFieldProps<T>> = props => <Field {...props} component={component} />;
    NewComp.displayName = component.displayName;
    return NewComp;
}

export const FormikSelect = wrapFormikField<SelectProps>(Select);
export const FormikText = wrapFormikField<TextFieldProps>(TextField);
// todo: Dan needs to fix this. It doesnt typecheck and i have no clue what it does
// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormikCurrency = wrapFormikField<CurrencyFieldProps & TextFieldProps>(CurrencyTextField as any);
export const FormikCheckbox = wrapFormikField<CheckboxWithLabelProps>(CheckboxWithLabel);

export interface BaseFieldDef<T> {
    label?: string;
    optional?: boolean;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validation?: z.Schema<any>;
    fieldProps?: FormikFieldProps<T>;
    helperText?: string | React.ReactNode;
    hidden?: boolean;
    layout?: FieldLayout;
    beforeContent?: React.ReactNode;
    afterContent?: React.ReactNode;
    overrideToEdit?: boolean;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderRecommendedValue?: (value: any) => React.ReactNode;
    key?: string;
}

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type FieldLayout = Partial<Record<Breakpoint, GridSize>>;
export type BaseFieldDefWithLayout<T> = BaseFieldDef<T> & { layout?: FieldLayout };

export type FieldDefText = BaseFieldDefWithLayout<Partial<TextFieldProps>> & {
    type: 'text';
    mask?: string;
};

export type FieldDefCurrency = BaseFieldDefWithLayout<Partial<CurrencyFieldProps & TextFieldProps>> & {
    type: 'currency';
    precision?: number;
};

export const PhoneField = Symbol('qf-phone-field');
export type FieldDefPhone = BaseFieldDefWithLayout<Partial<TextFieldProps>> & {
    type: typeof PhoneField;
};

export type FieldDefNumber = BaseFieldDefWithLayout<Partial<TextFieldProps>> & {
    type: 'number';
};

export type FieldDefDate = BaseFieldDefWithLayout<Partial<SimpleDatePickProps>> & {
    type: 'date';
};

export type FieldDefTime = BaseFieldDefWithLayout<Partial<SimpleTimePickerProps>> & {
    type: 'time';
};

export type FieldDefDateTime = BaseFieldDefWithLayout<Partial<SimpleDateTimePickProps>> & {
    type: 'datetime';
};

export type FieldDefSelect = BaseFieldDefWithLayout<
    Omit<SelectProps, 'multiple'> & {
        FormHelperTextProps?: TextFieldProps['FormHelperTextProps'];
        InputLabelProps?: Partial<InputLabelProps>;
    }
> & {
    type: 'select';
    options: (string | SimpleSelectOption)[];
    disableSortOptions?: boolean;
};

export type FieldDefAutoComplete = BaseFieldDefWithLayout<Partial<SimpleAutocompleteProps>> & {
    type: 'autocomplete';
    options: { value: string; label?: string; subtitle?: string }[] | string[];
    freeSolo?: boolean;
    cleanValue?: (value: string | null) => string | null;
};

export type FieldDefBoolean = BaseFieldDefWithLayout<CheckboxWithLabelProps> & {
    type: 'boolean';
    containerStyle?: React.CSSProperties;
    checkboxStyle?: React.CSSProperties;
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomQFComponentProps<CustomProps = any, FieldValue = any> = FormikFieldCompProps<
    FieldValue extends object ? PartialObjectDeep<FieldValue> : FieldValue | undefined
> &
    CustomProps;

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldDefCustom<CustomProps = any, FieldValue = any> = BaseFieldDefWithLayout<CustomProps> & {
    type: 'custom';
    component: React.FC<CustomQFComponentProps<CustomProps, FieldValue>>;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validation: z.Schema<any>;
    wrapperClassName?: string;
    hideErrorMessage?: boolean;
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface FormikFieldCompPropsWithValueType<V, VT extends any = any> extends FormikFieldCompProps<V> {
    field: Omit<FormikFieldCompProps<V>['field'], 'value'> & {
        value: VT | undefined | null;
    };
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QuickFormCustomFieldComponent<CustomProps = any, ValueProps = any, FormProps = any> = React.FC<
    FormikFieldCompPropsWithValueType<FormProps, ValueProps> & CustomProps
>;

export type FieldDefMultiSelect = BaseFieldDefWithLayout<Omit<SimpleMultiSelectProps, 'errorText'>> & {
    type: 'multiselect';
    options: (string | SimpleSelectOption)[];
    disableSortOptions?: boolean;
};

export type FieldDefArray<CustomProps = {}> = {
    type: 'array';
    label?: string;
    description?: string | React.ReactNode;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    of: FieldDefBasic<CustomProps> | FieldDefNested<any> | Omit<FieldDefMultiSelect, 'label'>;
    min?: number;
    max?: number;
    optional?: boolean;
    hidden?: boolean;
    elementName?: string;
    beforeContent?: React.ReactNode;
    afterContent?: React.ReactNode;
    overrideToEdit?: boolean;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderRecommendedValue?: (value: any) => React.ReactNode;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validation?: z.Schema<any>;
};

export type FieldDefNested<R extends AnyObj> = {
    type: 'nested';
    label?: string;
    helperText?: string;
    optional?: boolean;
    optionalLabel?: string;
    fields: FieldsDefProp<R>;
    hidden?: boolean;
    beforeContent?: React.ReactNode;
    afterContent?: React.ReactNode;
    overrideToEdit?: boolean;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderRecommendedValue?: (value: any) => React.ReactNode;
};

export type FieldDefRadioGroup = BaseFieldDefWithLayout<{
    value?: string;
    onChange: (value: string) => void;
    FormHelperTextProps?: TextFieldProps['FormHelperTextProps'];
}> & {
    type: 'radioGroup';
    options: string[] | SimpleSelectOption[];
    radioGroupStyle?: React.CSSProperties;
    InputLabelProps?: Partial<InputLabelProps>;
};

export type FieldDefAny<CustomProps = {}, R extends AnyObj = {}> =
    | FieldDefText
    | FieldDefNumber
    | FieldDefBoolean
    | FieldDefDate
    | FieldDefTime
    | FieldDefDateTime
    | FieldDefSelect
    | FieldDefAutoComplete
    | FieldDefCustom<CustomProps>
    | FieldDefArray
    | FieldDefCurrency
    | FieldDefPhone
    | FieldDefMultiSelect
    | FieldDefNested<R>
    | FieldDefRadioGroup;

export type QuickFormFieldType = FieldDefAny['type'];

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldDefBasic<CustomProps = {}, FieldValue = any> =
    | FieldDefText
    | FieldDefNumber
    | FieldDefBoolean
    | FieldDefDate
    | FieldDefTime
    | FieldDefDateTime
    | FieldDefSelect
    | FieldDefAutoComplete
    | FieldDefCurrency
    | FieldDefPhone
    | FieldDefMultiSelect
    | FieldDefCustom<CustomProps, FieldValue>
    | FieldDefRadioGroup;

export type QuickFormBasicFieldType = FieldDefBasic['type'];

export const FormikRootField: React.ComponentType<FieldConfig> = Field as React.ComponentType<FieldConfig>;

// this is tough. if it's an object { a: string, b: { c: string } } then the b definition must be nested
export type FieldsDefProp<R extends AnyObj> = {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [K in keyof R]: FieldDefBasic<any, R[K]> | FieldDefNested<R[K]> | FieldDefArray;
};
