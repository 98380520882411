import { default as constate } from 'constate';
import React from 'react';

export type LocalActionWithPayload<P> = { payload: P };

/**
 * this utility is a local reducer.
 *
 * similar to redux, except
 * . much lighter weight,
 * . selector internals are simpler, not memoized.
 *
 * @returns Provider constate wrapper to for local store.
 * @returns useAction action creator.
 * @returns useSelector state selector.
 * @returns useListener state listener.
 */
export const createLocalReducerContext = <
    S,
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    R extends { [K in string]: (state: S, action: { type: K } & LocalActionWithPayload<any>) => S },
>(
    reducers: R,
    initial: S,
) => {
    const listeners = new Set<(state: S) => void>();

    const [Provider, useReducer] = constate(() =>
        React.useReducer(<K extends keyof R>(state: S, action: Parameters<R[K]>[1]) => {
            const reducer = reducers[action.type] as (state: S, action: Parameters<R[K]>[1]) => S;
            const reduced = reducer(state, action);
            listeners.forEach(listener => listener(reduced));
            return reduced;
        }, initial),
    );

    const useAction = <K extends keyof R>(type: K & string): ((payload: Parameters<R[K]>[1]['payload']) => unknown) => {
        const [_state, dispatch] = useReducer();
        return React.useCallback(
            payload => {
                return dispatch({ type, payload });
            },
            [dispatch, type],
        );
    };

    const useSelector = <T>(selector: (s: S) => T): T => {
        const [state, _dispatch] = useReducer();
        return selector(state);
    };

    const useListener = (listener: (state: S) => void) =>
        React.useEffect(() => {
            listeners.add(listener);
            return () => void listeners.delete(listener);
        }, [listener]);

    return { Provider, useAction, useSelector, useListener };
};
