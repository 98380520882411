/* eslint-disable no-nested-ternary */
import { SimpleCheckbox } from '../SimpleForm';
import type { FieldDefAny, FieldsDefProp, FieldDefBasic, FieldDefNested, FieldDefArray } from './QuickForm.types';
import { FormikRootField } from './QuickForm.types';
import { buildQFInitialValues, InitialValMap } from './QuickForm.utils';
import { QuickFormField } from './QuickFormField';
import { QFMultiSelectField } from './fields/QFSelectField';
import type { Theme } from '@orthly/ui-primitives';
import {
    createStyles,
    makeStyles,
    Button,
    Collapse,
    Divider,
    FormHelperText,
    IconButton,
    FlossPalette,
    Icon,
    Grid,
    Text,
} from '@orthly/ui-primitives';
import type { FieldArrayRenderProps } from 'formik';
import { FieldArray, getIn, connect as connectFormik } from 'formik';
import type { FieldProps } from 'formik/dist/Field';
import _ from 'lodash';
import React from 'react';

interface QuickFormFieldListProps {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: FieldsDefProp<any>;
    fieldListStyle?: React.CSSProperties;
}

const OverrideSelectTextButton: React.FC<{
    onClick?: React.MouseEventHandler;
}> = ({ onClick, children }) => {
    return (
        <Button variant={'text'} size={'small'} onClick={onClick}>
            <span style={{ color: FlossPalette.STAR_GRASS }}>{children}</span>
        </Button>
    );
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OverrideWrapper: React.FC<{ label: string; fieldConfig: FieldDefAny<any, any>; formikProps: FieldProps }> = ({
    label,
    fieldConfig,
    formikProps,
    children,
}) => {
    const form = formikProps.form;
    const field = formikProps.field;
    const recommendation = form.initialValues[field.name];

    const [override, setOverride] = React.useState(false);
    // If recommendation changed and override=false, force field.value = recommendation
    React.useEffect(() => {
        // This could infinite loop if setFieldValue clones/modifies recommendation before
        // setting field.value. It should not.
        if (!override && field.value !== recommendation) {
            form.setFieldValue(field.name, recommendation);
        }
    }, [form, field.name, field.value, recommendation, override]);

    return (
        <Grid container>
            <Text
                variant={'body2'}
                style={{
                    marginLeft: 4,
                    marginBottom: 8,
                    width: '100%',

                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {override ? (
                    <>
                        <span style={{ color: '#7E7C78' }}>Override {label}</span>
                        <OverrideSelectTextButton
                            onClick={() => {
                                form.setFieldValue(field.name, recommendation);
                                setOverride(false);
                            }}
                        >
                            Back to default
                        </OverrideSelectTextButton>
                    </>
                ) : (
                    <>
                        <span style={{ color: '#000' }}>
                            {label}:
                            <span style={{ marginLeft: 4, fontWeight: 'bold', fontSize: '90%' }}>
                                {!!fieldConfig.renderRecommendedValue
                                    ? fieldConfig.renderRecommendedValue(field.value)
                                    : field.value}
                            </span>
                        </span>
                        <OverrideSelectTextButton
                            onClick={() => {
                                setOverride(true);
                            }}
                        >
                            Override
                        </OverrideSelectTextButton>
                    </>
                )}
            </Text>
            <Collapse in={override}>{children}</Collapse>
            <Collapse in={field.value !== recommendation}>
                <div style={{ padding: 16, background: FlossPalette.LIGHT_YELLOW, borderRadius: 8, width: '100%' }}>
                    <Text variant={'body2'}>You have selected a {label} different than the recommended one.</Text>
                </div>
            </Collapse>
        </Grid>
    );
};

interface QuickFormNestedFieldProps {
    name: string;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nestedField: FieldDefNested<any>;
}

export const QuickFormNestedField: React.ComponentType<QuickFormNestedFieldProps> =
    connectFormik<QuickFormNestedFieldProps>(({ nestedField, name, formik }) => {
        const rawError = getIn(formik.errors, name);
        const touched = getIn(formik.touched, name);
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const [initialValue] = React.useState<{ [k: string]: any }>(
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getIn(formik.values, name) || buildQFInitialValues<any>(nestedField.fields, {}),
        );
        const fieldDefined = !!getIn(formik.values, name);
        function toggleFieldDefined() {
            if (nestedField.optional) {
                formik.setFieldValue(name, fieldDefined ? null : initialValue);
            }
        }
        const error = !!touched && rawError && typeof rawError === 'string' ? rawError : undefined;
        const fields = Object.entries(nestedField.fields).reduce(
            (nestFields, [nestKey, nestedField]) => ({
                ...nestFields,
                [`${name}.${nestKey}`]: nestedField,
            }),
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {} as FieldsDefProp<any>,
        );
        if (nestedField.hidden) {
            return null;
        }
        return (
            <Grid container style={{ paddingTop: 5 }}>
                <Text gutterBottom variant={'h6'} style={{ width: '100%' }}>
                    {nestedField.label || _.startCase(name)}
                </Text>
                <FormHelperText
                    error={!!error}
                    style={{
                        width: '100%',
                        marginBottom: 5,
                        display: !error && !nestedField.helperText ? 'none' : undefined,
                    }}
                >
                    {error ? error : nestedField.helperText}
                </FormHelperText>
                {nestedField.optional && (
                    <SimpleCheckbox
                        checked={!fieldDefined}
                        setChecked={toggleFieldDefined}
                        label={nestedField.optionalLabel || 'Skip Optional Nested Field?'}
                    />
                )}
                {fieldDefined && (
                    <>
                        <Divider variant={'fullWidth'} style={{ width: '100%' }} />
                        <div
                            style={{
                                background: 'white',
                                width: '100%',
                                position: 'relative',
                                paddingLeft: 8,
                                paddingTop: 8,
                            }}
                        >
                            <QuickFormFieldList fields={fields} />
                        </div>
                        <Divider variant={'fullWidth'} style={{ width: '100%', margin: '5px 0' }} />
                    </>
                )}
            </Grid>
        );
    });

export function QuickFormFieldList(props: QuickFormFieldListProps) {
    return (
        <Grid container justifyContent={'flex-start'} spacing={1} style={props.fieldListStyle}>
            {
                // EPDPLT-4736: Using any is unsafe and should be avoided.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.entries<FieldDefAny<any, any>>(props.fields).map(([name, field]) => {
                    if (field.hidden) {
                        return null;
                    }

                    const fieldElement = (
                        <React.Fragment key={name}>
                            {field.beforeContent ?? null}
                            <QuickFormFieldListItem name={name} field={field} />
                            {field.afterContent ?? null}
                        </React.Fragment>
                    );

                    if (field.overrideToEdit) {
                        return (
                            <FormikRootField
                                key={name}
                                name={name}
                                render={formikProps => {
                                    return (
                                        <OverrideWrapper
                                            label={field.label || _.startCase(name)}
                                            fieldConfig={field}
                                            formikProps={formikProps}
                                        >
                                            {fieldElement}
                                        </OverrideWrapper>
                                    );
                                }}
                            />
                        );
                    }

                    return fieldElement;
                })
            }
        </Grid>
    );
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function QuickFormFieldListItem({ field, name }: { field: FieldDefAny<any, any>; name: string }) {
    if (field.type === 'array') {
        return (
            <FieldArray
                validateOnChange={true}
                name={name}
                render={arrayHelpers => <QuickFormArray name={name} arrayField={field} arrayHelpers={arrayHelpers} />}
            />
        );
    }
    if (field.type === 'nested') {
        return (
            <Grid item xs={12}>
                <QuickFormNestedField name={name} nestedField={field} />
            </Grid>
        );
    }
    return <QuickFormField {...field} name={name} />;
}

const useQuickFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 5,
        },
        itemRoot: {
            flexWrap: 'nowrap',
            padding: '10px 0px 10px 5px',
            margin: '5px 0',
        },
        itemLeft: {
            borderLeft: `5px solid ${theme.palette.primary.main}`,
            paddingLeft: 20,
        },
    }),
);

interface QuickFormArrayItemProps {
    itemKey: string;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    itemField: FieldDefBasic<any> | FieldDefNested<any>;
    idx: number;
    onRemove: (idx: number) => void;
}

export const QuickFormArrayItem: React.FC<QuickFormArrayItemProps> = props => {
    const classes = useQuickFormStyles();
    const { itemKey, itemField, idx, onRemove } = props;
    const itemLabel = itemField.label ? `${itemField.label} #${idx + 1}` : `#${idx + 1}`;
    return (
        <Grid container className={classes.itemRoot} alignItems={'flex-start'}>
            <Grid item xs={11} className={classes.itemLeft}>
                {itemField.type === 'nested' ? (
                    <QuickFormNestedField name={itemKey} nestedField={{ ...itemField, label: itemLabel }} />
                ) : (
                    <Grid container key={itemKey} justifyContent={'flex-start'}>
                        <QuickFormField name={itemKey} {...itemField} />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    onClick={() => onRemove(idx)}
                    color={'inherit'}
                    style={{ color: FlossPalette.ATTENTION }}
                    tabIndex={-1}
                >
                    <Icon icon={'RemoveIcon'} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

interface QuickFormArrayProps {
    arrayField: FieldDefArray;
    arrayHelpers: FieldArrayRenderProps;
    name: string;
}

export function QuickFormArray({ arrayField, arrayHelpers, name }: QuickFormArrayProps) {
    const classes = useQuickFormStyles();
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fieldEntries = (getIn(arrayHelpers.form.values, name) || []) as any[];
    const arrayError =
        typeof arrayHelpers.form.errors[arrayHelpers.name] === 'string'
            ? arrayHelpers.form.errors[arrayHelpers.name]
            : null;

    if (arrayField.hidden) {
        return null;
    }
    if (arrayField.of.type === 'multiselect') {
        return (
            <QFMultiSelectField
                field={{
                    ...arrayField.of,
                    name,
                    label: arrayField.label || _.startCase(arrayField.elementName ?? name),
                    helperText: arrayField.description || arrayField.of.helperText,
                }}
            />
        );
    }
    const onClickAdd = () => {
        const defaultNewItem =
            arrayField.of.type === 'nested'
                ? buildQFInitialValues(arrayField.of.fields, {})
                : arrayField.of.type === 'multiselect'
                  ? []
                  : InitialValMap[arrayField.of.type];
        arrayHelpers.push(defaultNewItem);
    };
    const showAddButton = arrayField.max ? fieldEntries.length < arrayField.max : true;
    return (
        <Grid item xs={12} container justifyContent={'center'} className={classes.root}>
            <Grid container alignItems={'center'}>
                <Text variant={'h6'}>{arrayField.label || _.startCase(name)}</Text>
                {showAddButton && (
                    <IconButton onClick={onClickAdd} color={'primary'} style={{ marginLeft: 10 }}>
                        <Icon icon={'AddIcon'} />
                    </IconButton>
                )}
            </Grid>
            {arrayField.description && (
                <Grid container alignItems={'center'} style={{ background: '#eee', padding: 10 }}>
                    {typeof arrayField.description === 'string' ? (
                        <Text variant={'body2'}>{arrayField.description}</Text>
                    ) : (
                        arrayField.description
                    )}
                </Grid>
            )}
            {arrayError && (
                <Grid container alignItems={'center'}>
                    <FormHelperText style={{ width: '100%' }} error={true}>
                        {arrayError}
                    </FormHelperText>
                </Grid>
            )}
            {fieldEntries.map((__entry, idx) => (
                <QuickFormArrayItem
                    key={`${name}.${idx}`}
                    onRemove={arrayHelpers.remove}
                    itemKey={`${name}.${idx}`}
                    idx={idx}
                    // EPDPLT-4736: Using any is unsafe and should be avoided.
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    itemField={arrayField.of as FieldDefBasic | FieldDefNested<any>}
                />
            ))}
            {(fieldEntries.length > 0 || arrayError) && (
                <Grid item xs={12} className={classes.itemRoot} style={{ paddingTop: 0 }}>
                    {showAddButton && (
                        <Button style={{ marginTop: 10 }} variant={'contained'} type={'button'} onClick={onClickAdd}>
                            Add {arrayField.elementName ?? name}
                        </Button>
                    )}
                </Grid>
            )}
        </Grid>
    );
}
