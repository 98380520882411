import type { FetchResult } from '@apollo/client';
import type {
    LabsGqlCreateShippingLabelMutationVariables,
    LabsGqlDoctorDeliveryAddressFragment,
    LabsGqlCreateShippingLabelMutation,
} from '@orthly/graphql-operations';
import { useCreateShippingLabelMutation, useOrderRefetch } from '@orthly/graphql-react';
import {
    LabsGqlPortalShipDestinationType as DestinationType,
    LabsGqlPortalShipShippingSpeed as ShippingSpeed,
} from '@orthly/graphql-schema';
import { QuickForm, useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import { FlossPalette, Collapse, Text } from '@orthly/ui-primitives';
import { ShippingSpeedToText, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

type DeliveryAddress = Omit<LabsGqlDoctorDeliveryAddressFragment, '__typename'>;

type MutationArgs = LabsGqlCreateShippingLabelMutationVariables['data'];

type CreateLabelResponse = FetchResult<LabsGqlCreateShippingLabelMutation>;

/** Modal for creating a shipping label. */
export const CreateShippingLabelModal: React.VFC<{
    hideButton: boolean;
    isOpen: boolean;
    mailingAddress: DeliveryAddress;
    salesOrderId: string;
    shippingSpeed: ShippingSpeed;
    setOpen: (open: boolean) => void;
}> = ({ hideButton, isOpen, mailingAddress, salesOrderId, shippingSpeed, setOpen }) => {
    const refetchOrder = useOrderRefetch();
    const { value: requireExtraClick = false } = useFeatureFlag('requireExtraClickForShippingLabel');
    const [mutation] = useCreateShippingLabelMutation();
    const successMessageText = requireExtraClick
        ? 'Click the Shipping Label button on the order when packing is complete.'
        : 'Shipping label created!';
    const { submit, submitting } = useChangeSubmissionFn<CreateLabelResponse, [MutationArgs]>(
        (data: MutationArgs) => mutation({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => [successMessageText, {}],
            onSuccess: res => {
                if (!requireExtraClick && res.data?.createShippingLabel) {
                    window.open(res.data?.createShippingLabel, '_blank', 'noreferrer noopener');
                }
                refetchOrder(salesOrderId);
                setOpen(false);
            },
        },
    );

    const [destinationType, setDestinationType] = React.useState<DestinationType | null>(null);
    // TODO: Move this business logic into a view model
    // server-generated shipping method assumes ship-to-dentist. If we ship to Dandy, always overnight, to minimize
    // delay to dentist
    const shippingMethod = destinationType === DestinationType.Dandy ? ShippingSpeed.Overnight : shippingSpeed;

    return (
        <RootActionDialog
            buttonProps={{ fullWidth: false }}
            buttonText={'Create Shipping Label'}
            hideButton={hideButton}
            loading={submitting}
            open={isOpen}
            setOpen={setOpen}
            style={{ padding: 0 }}
            title={'Create a shipping label'}
            content={
                <QuickForm<Omit<MutationArgs, 'order_id' | 'shipping_speed'>>
                    fields={{
                        destination_type: {
                            type: 'radioGroup',
                            options: [
                                { label: 'Ship to Dandy', value: DestinationType.Dandy },
                                { label: 'Ship to Practice', value: DestinationType.Practice },
                            ],
                        },
                        override_address_validation: {
                            type: `boolean`,
                            label: `Override Address Validation`,
                            afterContent: (
                                <div>
                                    <Collapse in={destinationType === DestinationType.Practice}>
                                        <div style={{ padding: 16 }}>
                                            <Text variant={'caption'}>Practice address</Text>
                                            <Text variant={'body2'}>
                                                {mailingAddress.street_one},{' '}
                                                {mailingAddress.street_two && `${mailingAddress.street_two}, `}
                                                {mailingAddress.city}, {mailingAddress.country}{' '}
                                                {mailingAddress.postal_code}
                                            </Text>
                                        </div>
                                    </Collapse>
                                    <div style={{ padding: '8px', color: FlossPalette.GRAY }}>
                                        Shipping Speed: {ShippingSpeedToText[shippingMethod]}
                                    </div>
                                </div>
                            ),
                        },
                    }}
                    initialValues={{}}
                    onChange={form => {
                        setDestinationType(form.destination_type ?? null);
                    }}
                    submitButtonProps={{
                        children: 'Create shipping label',
                        startIcon: 'TruckIconOutlined',
                    }}
                    onSubmit={async result => {
                        await submit({
                            destination_type: result.destination_type,
                            order_id: salesOrderId,
                            override_address_validation: result.override_address_validation,
                            shipping_speed: shippingMethod,
                        });
                    }}
                />
            }
        />
    );
};

/** Button for opening the create shipping label modal. */
export const CreateShippingLabelButton: React.VFC<{
    mailingAddress: DeliveryAddress;
    salesOrderId: string;
    shippingSpeed: ShippingSpeed;
}> = ({ mailingAddress, salesOrderId, shippingSpeed }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <CreateShippingLabelModal
            hideButton={false}
            isOpen={open}
            mailingAddress={mailingAddress}
            salesOrderId={salesOrderId}
            setOpen={setOpen}
            shippingSpeed={shippingSpeed}
        />
    );
};
