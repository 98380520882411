import type { ActionsClass } from './TableStateActions';
import { TableStateActions } from './TableStateActions';
import { useTableStateContext } from './TableStateContext';

export function useTableStateAction<K extends keyof ActionsClass = keyof ActionsClass>(
    action: K,
): (...args: Parameters<ActionsClass[K]>) => void {
    const { dispatch } = useTableStateContext();
    return (...args) => {
        const actionCreator = TableStateActions[action];
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(actionCreator(...(args as never[])) as any);
    };
}

export function useAllTableStateActions() {
    const { dispatch } = useTableStateContext();
    return new TableStateActions(dispatch);
}
