import { useTranslationOptionsContext } from '../../contexts/TableOptionsContext';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, IconButton, KeyboardArrowLeft, KeyboardArrowRight } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                flexShrink: 0,
                color: theme.palette.text.secondary,
                marginLeft: theme.spacing(2.5),
            },
        }),
    { name: 'MUITablePaginationActions' },
);

interface TablePaginationActionsProps {
    count: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    page: number;
    rowsPerPage: number;
}

export const MUITablePaginationActions = (props: TablePaginationActionsProps) => {
    const classes = useStyles();
    const { onPageChange, page, rowsPerPage, count } = props;
    // we subtract 1 because our pages start at index 0
    const lastPage = React.useMemo(() => Math.ceil(count / rowsPerPage) - 1, [count, rowsPerPage]);
    const textLabels = useTranslationOptionsContext().pagination;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFirstPageButtonClick = (e: any) => {
        onPageChange(e, 0);
    };
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleBackButtonClick = (e: any) => {
        onPageChange(e, page - 1);
    };
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleNextButtonClick = (e: any) => {
        onPageChange(e, page + 1);
    };
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLastPageButtonClick = (e: any) => {
        onPageChange(e, lastPage);
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={textLabels.first}
                data-testid={'First Page'}
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label={textLabels.previous}
                data-testid={'Previous Page'}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= lastPage}
                aria-label={textLabels.next}
                data-testid={'Next Page'}
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= lastPage}
                aria-label={textLabels.last}
                data-testid={'Last Page'}
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};
