import { useTableData } from '../../contexts/TableDataContext';
import { useDisplayOptionsContext } from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import type { Column } from '../../types';
import { MUITableSelectCell } from '../Body/MUITableSelectCell';
import { MUITableDetailPanelHeadCell, MUITableHeadCell } from './MUITableHeadCell';
import { TableHeadRow } from './MUITableHeadRow';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, TableHead as MuiTableHead } from '@orthly/ui-primitives';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            main: {},
            responsiveStacked: {
                [theme.breakpoints.down('md')]: {
                    display: 'none',
                },
            },
        }),
    { name: 'MUITableHead' },
);

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MUITableHead = (props: { columns: Column<any>[] }) => {
    const { columns } = props;
    const classes = useStyles();
    const selectedRows = useMuiTableSelector(s => s.selectedRows);
    const rowsPerPage = useMuiTableSelector(s => s.pagination.rowsPerPage);
    const hiddenColumnNames = useMuiTableSelector(s => s.hiddenColumnNames);
    const { DetailPanel, responsive } = useDisplayOptionsContext();
    const { totalRowCount } = useTableData();
    const numSelected = selectedRows.length;
    const isChecked = numSelected === rowsPerPage || numSelected === totalRowCount;
    return (
        <MuiTableHead
            className={classNames({ [classes.responsiveStacked]: responsive === 'stacked', [classes.main]: true })}
        >
            <TableHeadRow>
                {DetailPanel && <MUITableDetailPanelHeadCell />}
                <MUITableSelectCell checked={isChecked} isHeaderCell={true} />
                {columns
                    .filter(c => !hiddenColumnNames.includes(c.name))
                    .map((column, index) => (
                        <MUITableHeadCell key={column.name} index={index} column={column}>
                            {column.title ? column.title : _.startCase(column.name)}
                        </MUITableHeadCell>
                    ))}
            </TableHeadRow>
        </MuiTableHead>
    );
};
