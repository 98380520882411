import { useSession } from '@orthly/session-client';
import type { StatsigExperiments, StatsigDandyUser } from '@orthly/shared-types';
import type { ExperimentEvaluationOptions, Experiment } from '@statsig/client-core';
import { useStatsigClient, StatsigProvider, useClientAsyncInit, useStatsigUser } from '@statsig/react-bindings';
import React from 'react';

export { useStatsigClient, useLayer } from '@statsig/react-bindings';

export const ANONYMOUS_STATSIG_USER = { userID: 'anonymous' };

const STATSIG_KEY = process.env.STATSIG_CLIENT_SDK_KEY || 'TEST_SDK_KEY';
const STATSIG_ENVIRONMENT = process.env.STATSIG_ENVIRONMENT_TIER || 'development';

type StatsigExperiment<ExperimentName extends keyof StatsigExperiments> = Omit<Experiment, 'value' | 'get'> & {
    value: StatsigExperiments[ExperimentName]['schema'];
    get: <Key extends keyof StatsigExperiments[ExperimentName]['schema']>(
        key: Key,
        defaultValue?: StatsigExperiments[ExperimentName]['schema'][Key],
    ) => StatsigExperiments[ExperimentName]['schema'][Key];
};

type StatsigSubExperiment<
    ExperimentName extends keyof StatsigExperiments,
    SubExperimentName extends keyof StatsigExperiments[ExperimentName]['subExperiments'],
> = Omit<Experiment, 'value'> & {
    value: StatsigExperiments[ExperimentName]['subExperiments'][SubExperimentName];
};

export const useExperiment = <P extends keyof StatsigExperiments>(
    experimentName: P,
    options?: ExperimentEvaluationOptions,
) => {
    const { client } = useStatsigClient();

    /**
     * This allows us to mock experiments in Storybook or Jest.
     * We can set the mock experiments in the Storybook parameters.
     * This code gets stripped out in production builds.
     */
    if (process.env.STORYBOOK && (window as any).STATSIG_MOCK_EXPERIMENTS) {
        const experiment: Record<string, StatsigExperiment<P>> = (window as any).STATSIG_MOCK_EXPERIMENTS[
            experimentName
        ];
        const getter = {
            value: experiment,
            get: (key: string, defaultValue?: any) => {
                return experiment?.[key] || defaultValue || {};
            },
        };
        // Double type assertions are unsafe and should be avoided.
        // eslint-disable-next-line @orthly/no-unknown-or-any-cast
        return getter as unknown as StatsigExperiment<P>;
    }

    // Double type assertions are unsafe and should be avoided.
    // eslint-disable-next-line @orthly/no-unknown-or-any-cast
    return client.getExperiment(experimentName, options) as unknown as StatsigExperiment<P>;
};

/**
 * Given an experiment name, returns the sub-experiment if the parent experiment is in treatment.
 * See a full list of experiments and affiliated sub-experiments in `shared-libs/shared-types/src/config/statsig.types.ts`.
 * @param experimentName The parent experiment name
 * @param subExperimentName The sub-experiment name (must be inside `experimentName`'s `subExperiments`)
 * @param experimentOptions Options for the parent experiment
 * @param subExperimentOptions Options for the sub-experiment
 */
export const useSubExperiment = <
    P extends keyof StatsigExperiments, // Parent experiment name
    S extends keyof StatsigExperiments[P]['subExperiments'], // Sub-experiment name
>(
    experimentName: P,
    subExperimentName: S,
    experimentOptions?: ExperimentEvaluationOptions,
    subExperimentOptions?: ExperimentEvaluationOptions,
) => {
    const { client } = useStatsigClient();

    const experiment = client.getExperiment(experimentName, experimentOptions);
    if (experiment.groupName === 'Test') {
        return client.getExperiment(
            subExperimentName as string,
            subExperimentOptions,
            // Double type assertions are unsafe and should be avoided.
            // eslint-disable-next-line @orthly/no-unknown-or-any-cast
        ) as unknown as StatsigSubExperiment<P, S>;
    }
};

const useSessionUser = () => {
    const session = useSession();
    if (!session?.user_id) {
        return ANONYMOUS_STATSIG_USER;
    }

    const user: StatsigDandyUser = {
        userID: session.user_id,
        custom: {
            organization_id: session?.organization_id || 'NA',
            organization_type: session?.organization_type || 'UNKNOWN',
        },
        customIDs: {
            Organization_ID: session?.organization_id || 'NA',
        },
    };
    if (session.roles) {
        session.roles.forEach(role => {
            user.custom[role] = true;
        });
    }
    if (session.activated_at) {
        user.custom.activated_at = session.activated_at.toISOString();
    }
    if (session.created_at) {
        user.custom.created_at = session.created_at.toISOString();
    }
    return user;
};

const UpdateUserGuard: React.FC = ({ children }) => {
    const { updateUserAsync } = useStatsigUser();
    const user = useSessionUser();

    React.useEffect(() => {
        void updateUserAsync(user);
        // We explicitly only change this if the userId changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.userID]);

    return children as React.ReactElement;
};

export const DandyStatsigProvider: React.FC<{ fallback?: React.ComponentType }> = ({
    children,
    fallback: Fallback,
}) => {
    const user = useSessionUser();

    const { client, isLoading } = useClientAsyncInit(STATSIG_KEY, user, { environment: { tier: STATSIG_ENVIRONMENT } });

    if (Fallback && isLoading) {
        return <Fallback />;
    }

    return (
        <StatsigProvider client={client}>
            <UpdateUserGuard>{children}</UpdateUserGuard>
        </StatsigProvider>
    );
};
