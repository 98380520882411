import { checkIfNotesAreRequired } from './components/StructuredRejectionNotes/StructuredRejectionNotes.util';
import { isUnfinishedPreset, presetHasValidSelections } from './hooks/useUnfinishedPresets';
import { useGuidedWaxupContext } from './state/GuidedWaxupContext';
import type { PresetsRecord } from './state/GuidedWaxupState';
import { useGuidedWaxupSelector } from './state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { ModelAppearance, PayloadModelAppearance } from '@orthly/dentin';
import { applyVisibility } from '@orthly/dentin';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlSubmitWaxupReviewMutationVariables } from '@orthly/graphql-operations';
import { useSubmitWaxupReviewMutation } from '@orthly/graphql-react';
import type { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';
import {
    DESIGN_ORDER_PRESET_CATEGORIES,
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
} from '@orthly/graphql-schema';
import type { SimpleMenuPropsItem } from '@orthly/ui';
import {
    LowerJawIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
    ToothFilledIcon,
    UpperJawIcon,
    useChangeSubmissionFn,
} from '@orthly/ui';
import { FlossPalette, Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export interface PresetTabs {
    label: string;
    value: LabsGqlGuidedWaxupPresetType;
}

export function isGuidedPreset(selectedTab: LabsGqlGuidedWaxupPresetType) {
    return selectedTab !== LabsGqlGuidedWaxupPresetType.GeneralView;
}

export function makeTitleCase(presetType: string) {
    return _.startCase(presetType.toLowerCase());
}

export function hasFullStructuredNotesSelection(
    presetType: LabsGqlGuidedWaxupPresetType,
    structuredNotes: LabsGqlDesignOrderNoteCategory[] = [],
) {
    // The user must have at least one leaf node selected within the options tree
    const leafOptions = DESIGN_ORDER_PRESET_CATEGORIES[presetType];
    return structuredNotes.some(note => leafOptions.includes(note));
}

export function useShouldDisableTabs() {
    const presets = useGuidedWaxupSelector(s => s.presets);
    const {
        enableStructuredDesignReviewNotes,
        selectedTab,
        isAnnotatingScreenshot,
        mostRecentlyRejectedDesignFragment,
        selectedDesignRevisionAlreadyReviewed,
    } = useGuidedWaxupContext();

    if (isAnnotatingScreenshot) {
        return true;
    }

    const selectedPreset = presets[selectedTab];
    const presetRejected = selectedPreset?.status === LabsGqlGuidedWaxupPresetStatus.Rejected;
    const notesAreRequired = checkIfNotesAreRequired(
        selectedPreset,
        selectedTab,
        mostRecentlyRejectedDesignFragment,
        selectedDesignRevisionAlreadyReviewed,
    );
    const hasNotes = (selectedPreset?.notes || '').length > 0;
    if (notesAreRequired && !hasNotes) {
        return true;
    }

    if (enableStructuredDesignReviewNotes) {
        // The general view should be considered incomplete if any of the other presets are incomplete
        if (!isGuidedPreset(selectedTab)) {
            return Object.entries(presets).some(([presetType, preset]) => {
                const isUnfinished = isUnfinishedPreset(
                    presetType as LabsGqlGuidedWaxupPresetType,
                    preset,
                    selectedDesignRevisionAlreadyReviewed,
                );
                if (isUnfinished) {
                    return true;
                }

                const notesAreRequired = checkIfNotesAreRequired(
                    preset,
                    presetType as LabsGqlGuidedWaxupPresetType,
                    mostRecentlyRejectedDesignFragment,
                    selectedDesignRevisionAlreadyReviewed,
                );
                const hasNotes = (preset?.notes || '').length > 0;
                return notesAreRequired && !hasNotes;
            });
        }

        // A preset is considered incomplete if it is rejected and has no structured notes
        return presetRejected && !presetHasValidSelections(selectedTab, selectedPreset);
    }

    // A preset is considered incomplete if it is rejected and has no notes
    return presetRejected && !selectedPreset?.notes;
}

export function usePresetTabClick() {
    const { setSelectedTab, selectedDesignRevisionAlreadyReviewed, setIsIncompletedWorkReminderModalOpen } =
        useGuidedWaxupContext();
    const disableTabs = useShouldDisableTabs();
    return (tab: PresetTabs) => {
        // to assist doctors in navigating the guided waxup flow, if the tabs are disabled
        // because the doctor rejected the design but hasn't provided a rejection note yet,
        // or if they are currently annotating a markup, then we display a pop-up reminder
        // to complete the actions before navigating away from the current preset.
        if (!disableTabs) {
            BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
                AssetName: `Guided Waxup Preset Tab - ${tab.label}`,
                AssetType: 'button',
                AssetVersion: '',
                AssetCTAText: tab.label,
            });
            setSelectedTab(tab.value);
        } else {
            // if viewing previous design, this is a no-op
            // otherwise, show modal
            if (!selectedDesignRevisionAlreadyReviewed) {
                setIsIncompletedWorkReminderModalOpen(true);
            }
        }
    };
}

export function useGetSelectedWaxup() {
    const { selectedDesignFragment } = useGuidedWaxupContext();
    return React.useMemo(
        () => getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment)?.doctor_review,
        [selectedDesignFragment],
    );
}

export function usePresetStatus({ presetType }: { presetType: LabsGqlGuidedWaxupPresetType }) {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const selectedWaxup = useGetSelectedWaxup();

    return React.useMemo(() => {
        if (selectedDesignRevisionAlreadyReviewed) {
            const isWaxupRejected = selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected;
            const selectedRejectionPreset = isWaxupRejected
                ? selectedWaxup?.annotations?.find(preset => preset.preset_type === presetType)
                : undefined;

            return selectedRejectionPreset?.preset_status;
        }

        return presets[presetType]?.status;
    }, [presetType, presets, selectedDesignRevisionAlreadyReviewed, selectedWaxup]);
}

export function useReviewHasStructuredNotes() {
    const selectedWaxup = useGetSelectedWaxup();
    return selectedWaxup?.annotations?.some(annotation => !!annotation.structured_notes?.length) ?? false;
}

const CONTENT_BY_PRESET_TYPE = {
    [LabsGqlGuidedWaxupPresetType.ToothDesign]: {
        title: 'Tooth Design',
        mobileTitle: 'Tooth Design',
        questionTitle: 'Is the tooth design correct?',
    },
    [LabsGqlGuidedWaxupPresetType.MarginView]: {
        title: 'Margin',
        mobileTitle: 'Margin',
        questionTitle: 'Is this margin accurate?',
    },
    [LabsGqlGuidedWaxupPresetType.AnteriorContour]: {
        title: 'Anterior Contour',
        mobileTitle: 'Anterior Contour',
        questionTitle: 'Are the anterior contours correct?',
    },
    [LabsGqlGuidedWaxupPresetType.ContourView]: {
        title: 'Contour',
        mobileTitle: 'Contour',
        questionTitle: 'Are the contours correct?',
    },
    [LabsGqlGuidedWaxupPresetType.PosteriorContour]: {
        title: 'Posterior Contour',
        mobileTitle: 'Posterior Contour',
        questionTitle: 'Are the posterior contours correct?',
    },
    [LabsGqlGuidedWaxupPresetType.ContactDesign]: {
        title: 'Contact Design',
        mobileTitle: 'Contact Design',
        questionTitle: 'Is the interproximal contact and embrasure design correct?',
    },
    [LabsGqlGuidedWaxupPresetType.OcclusalAnatomy]: {
        title: 'Occlusal Anatomy',
        mobileTitle: 'Occlusal',
        questionTitle: 'Is the occlusal design correct?',
    },
    [LabsGqlGuidedWaxupPresetType.FacialAnatomy]: {
        title: 'Facial Anatomy',
        mobileTitle: 'Facial',
        questionTitle: 'Is the facial design correct?',
    },
    [LabsGqlGuidedWaxupPresetType.MarginalRidge]: {
        title: 'Marginal Ridge',
        mobileTitle: 'Marginal Ridge',
        questionTitle: 'Is the marginal ridge design correct?',
    },
    [LabsGqlGuidedWaxupPresetType.GeneralView]: {
        title: 'General View',
        mobileTitle: 'General',
        questionTitle: '',
    },
    [LabsGqlGuidedWaxupPresetType.VideoWalkthrough]: {
        title: 'Video Design Review',
        mobileTitle: 'Video Design Review',
        questionTitle: '',
    },
} satisfies Record<LabsGqlGuidedWaxupPresetType, { title: string; mobileTitle: string; questionTitle: string }>;
const isValidPresetType = (presetType: string): presetType is LabsGqlGuidedWaxupPresetType =>
    presetType in CONTENT_BY_PRESET_TYPE;

export function getPresetTitle(presetType: string, isMobile?: boolean) {
    if (!isValidPresetType(presetType)) {
        return 'Unknown Preset';
    }
    return isMobile ? CONTENT_BY_PRESET_TYPE[presetType].mobileTitle : CONTENT_BY_PRESET_TYPE[presetType].title;
}

export function getGuidedPresetQuestionTitle(presetType: LabsGqlGuidedWaxupPresetType) {
    return CONTENT_BY_PRESET_TYPE[presetType].questionTitle;
}

export function useGuidedWaxupTabs(presets: PresetsRecord): PresetTabs[] {
    const { enableStructuredDesignReviewNotes } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return React.useMemo(() => {
        const filteredPresets: PresetTabs[] = [];

        for (const [preset, presetInfo] of Object.entries(presets)) {
            const shouldShowPreset = enableStructuredDesignReviewNotes || presetInfo.status !== 'SKIPPED';

            if (isValidPresetType(preset) && shouldShowPreset) {
                filteredPresets.push({
                    label: getPresetTitle(preset, isMobile),
                    value: preset,
                });
            }
        }

        return filteredPresets;
    }, [enableStructuredDesignReviewNotes, isMobile, presets]);
}

export function getAndFormatGuidedPresetAnnotations(
    rejection?: OrderDesignPreviewDesign_FragmentFragment['doctor_review'],
) {
    const rejectionAnnotations = rejection?.annotations?.flatMap(preset => preset.annotated_image_urls);
    return rejectionAnnotations?.map(annotation => ({
        comment: '',
        image_url: annotation,
    }));
}

export const PresetStatusIndicator: React.VFC<{ presetType: LabsGqlGuidedWaxupPresetType }> = ({ presetType }) => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();
    const selectedWaxup = useGetSelectedWaxup();
    const presetStatus = usePresetStatus({ presetType });

    if (presetType === LabsGqlGuidedWaxupPresetType.VideoWalkthrough) {
        return null;
    }

    // If selected waxup is an approval, then every preset was approved and we just show the thumbs up.
    if (selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved) {
        return <ThumbsUpIcon sx={{ color: FlossPalette.GRAY }} />;
    }

    switch (presetStatus) {
        case LabsGqlGuidedWaxupPresetStatus.Approved:
            return <ThumbsUpIcon sx={{ color: FlossPalette.GRAY }} />;
        case LabsGqlGuidedWaxupPresetStatus.Rejected:
            return (
                <ThumbsDownIcon
                    sx={{
                        color: selectedDesignRevisionAlreadyReviewed
                            ? FlossPalette.GRAY
                            : FlossPalette.SECONDARY_FOREGROUND,
                    }}
                />
            );

        case LabsGqlGuidedWaxupPresetStatus.Skipped:
            return (
                <Text variant={'body2'} medium color={'GRAY'} sx={{ width: '100%' }}>
                    Incomplete
                </Text>
            );
        default:
            presetStatus satisfies undefined;
    }

    return null;
};

export function toggleAction(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
    pma: PayloadModelAppearance,
) {
    return function (checked: boolean) {
        setAppearance(app => {
            // CAD visibility is the anchor
            const cadAppearance = applyVisibility(
                app.restoratives,
                checked,
                candidate => candidate.payloadModel.name === pma.payloadModel.name,
            );
            const preExtractionScansAppearance = applyVisibility(
                app.preExtractionScans,
                checked,
                candidate => candidate.payloadModel.name === pma.payloadModel.name,
            );
            return {
                ...app,
                restoratives: cadAppearance,
                preExtractionScans: preExtractionScansAppearance,
            };
        });
    };
}

export const VisibilityToggleIcon: React.VFC<{ toggleName: string; toggled: boolean }> = ({ toggleName, toggled }) => {
    switch (toggleName) {
        case 'Upper':
            return (
                <UpperJawIcon
                    style={{
                        color: toggled ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                    }}
                />
            );
        case 'Lower':
            return (
                <LowerJawIcon
                    style={{
                        color: toggled ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                    }}
                />
            );
        default:
            return (
                <ToothFilledIcon
                    style={{
                        color: toggled ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                        margin: '4px -6px 0px 3px',
                    }}
                />
            );
    }
};
export const useSubmitGuidedWaxupReview = (onSubmit?: () => Promise<void>) => {
    const [submitMtn] = useSubmitWaxupReviewMutation();
    const mtnSubmitter = (variables: LabsGqlSubmitWaxupReviewMutationVariables) => submitMtn({ variables });
    return useChangeSubmissionFn<any, [LabsGqlSubmitWaxupReviewMutationVariables]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Waxup review submitted!`, {}],
        onSuccess: async () => {
            if (onSubmit) {
                await onSubmit();
            }
        },
    });
};

export const useTimelineAndImagesMenuItems = () => {
    const { setIsMobileTimelineDialogOpen, setIsMobileDoctorImagesDialogOpen, OrderChatWrapper } =
        useGuidedWaxupContext();
    const timelineItem: SimpleMenuPropsItem = {
        onClick: (setClosed: () => void) => {
            setIsMobileTimelineDialogOpen(true);
            setClosed();
        },
        label: 'Timeline',
    };

    const imagesItem: SimpleMenuPropsItem = {
        onClick: (setClosed: () => void) => {
            setIsMobileDoctorImagesDialogOpen(true);
            setClosed();
        },
        label: 'Images',
    };

    // If OrderChatWrapper isn't provided, then there isn't data to render for the timeline, and we exclude
    // it as an option
    return OrderChatWrapper ? [timelineItem, imagesItem] : [imagesItem];
};

export interface OpposingArchControls {
    isUpper: boolean;
    filterValue: boolean;
    toggle: () => void;
}

function useTabNavigation(delta: 1 | -1) {
    const { selectedTab, setSelectedTab } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const tabs = Object.keys(presets);
    const currentTabIndex = tabs.findIndex(tab => selectedTab === tab);

    return () => {
        setSelectedTab(
            (tabs[currentTabIndex + delta] as LabsGqlGuidedWaxupPresetType) ?? LabsGqlGuidedWaxupPresetType.GeneralView,
        );
    };
}

export const useNextTabNavigation = () => useTabNavigation(1);
export const usePrevTabNavigation = () => useTabNavigation(-1);

export function useIsRejectionNoteRequired() {
    const { selectedTab } = useGuidedWaxupContext();
    const generalViewRejected = useGuidedWaxupSelector(
        s => s.presets[LabsGqlGuidedWaxupPresetType.GeneralView]?.status === LabsGqlGuidedWaxupPresetStatus.Rejected,
    );
    return selectedTab !== LabsGqlGuidedWaxupPresetType.GeneralView || generalViewRejected;
}
