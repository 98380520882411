import { FileUploaderBulk } from '../FirebaseUpload/FileUploaderBulk';
import { CompleteDesignTaskPaneAlert } from '../OrderDesign/OrderDesignTaskPaneAlert';
import { useValidateDesign } from '../OrderDesign/OrderDesignTaskValidation.hooks.graphql';
import type { LabsGqlAttachDesignFilesMutationVariables, LabsGqlOrder } from '@orthly/graphql-operations';
import { useAttachDesignFilesMutation } from '@orthly/graphql-react';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Button, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    buttonsRow: {
        paddingTop: 8,
    },
    replaceDesignFilePaper: {
        zIndex: theme.zIndex.modal + 1100,
    },
}));

interface UploadDesignFileProps {
    CustomButton: React.FC<{ onClick: () => void; text: string }>;
    onClose: () => void;
    onOpen?: () => void;
    order: Pick<LabsGqlOrder, 'id' | 'design_file_path'>;
    refetchOrder: () => Promise<any>;
}

export const UploadDesignFileAction: React.FC<UploadDesignFileProps> = props => {
    type Vars = LabsGqlAttachDesignFilesMutationVariables['data'];

    const classes = useStyles();

    const { order, CustomButton } = props;

    const [submitMtn] = useAttachDesignFilesMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const [currentTime] = React.useState<number>(Date.now());

    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Design files attached!', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            props.onClose();
        },
    });

    React.useEffect(() => {
        if (open) {
            props.onOpen?.();
        }
    }, [open, props]);

    const [uploadedPath, setUploadedPath] = React.useState<string | null>(null);

    const title = order.design_file_path ? 'Replace Design File' : 'Upload Design File';

    const {
        result: validationResult,
        reset: resetValidation,
        checkDesignValid,
        validationLoading,
    } = useValidateDesign(order.id);

    const didValidationFail = validationResult?.status === 'failed';
    const validationFailedAndNotBypassable = validationResult?.status === 'failed' && !validationResult.canBypass;
    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.designs,
        order.id,
        `${currentTime}`,
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={isOpenNew => {
                setOpen(isOpenNew);
                !isOpenNew && props.onClose();
            }}
            PaperProps={{
                className: classes.replaceDesignFilePaper,
            }}
            title={title}
            content={
                <Grid container>
                    <FileUploaderBulk
                        autoSubmit
                        elevation={0}
                        storagePathConfig={storagePathConfig}
                        paperStyle={{ padding: '0 0 8px' }}
                        onComplete={results => {
                            const path = results[0]?.uploadedPath;
                            if (path) {
                                resetValidation();
                                setUploadedPath(path);
                            }
                        }}
                        onReset={() => {
                            setUploadedPath(null);
                            resetValidation();
                        }}
                        prependTimestampToFilename={false} // Should keep the design filenames as they are when exported
                    />
                    <CompleteDesignTaskPaneAlert result={validationResult} />
                    <Grid container direction={'row'} className={classes.buttonsRow}>
                        <Grid item xs />
                        <Grid item>
                            <Button
                                variant={didValidationFail ? 'alert-secondary' : 'primary'}
                                disabled={validationFailedAndNotBypassable || !uploadedPath || validationLoading}
                                onClick={async () => {
                                    // Should never happen
                                    if (!uploadedPath) {
                                        return;
                                    }

                                    if (!(await checkDesignValid(uploadedPath))) {
                                        return;
                                    }

                                    void submit({
                                        orderId: order.id,
                                        design_file_path: uploadedPath,
                                        // We explicitly set validation as overridden because we are handling validation ourselves upfront.
                                        override_validation: true,
                                    });
                                }}
                            >
                                {validationResult ? 'Attach file anyway' : 'Attach design file'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            buttonText={title}
            CustomButton={() => <CustomButton text={title} onClick={() => setOpen(true)} />}
        />
    );
};
