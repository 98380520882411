import { useExperiment } from '../../../Providers/Statsig';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';

const ENABLED_SKUS: LabsGqlOrderItemSkuType[] = [
    LabsGqlOrderItemSkuType.Bridge,
    LabsGqlOrderItemSkuType.Crown,
    LabsGqlOrderItemSkuType.Model,
];

export const useStructuredNotesExperiment = (order: Pick<LabsGqlOrder, 'items_v2'>) => {
    const experiment = useExperiment('ddp_structured_notes_2024_');

    if (!order) {
        return false;
    }

    const isEnabledForOrder = order.items_v2.every(item => ENABLED_SKUS.includes(item.sku));
    return Boolean(isEnabledForOrder && experiment.get('ddp_reject_reason_nested_checkbox', false));
};
