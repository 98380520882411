import type { ActionCreatorWithType } from '@orthly/redux-async-actions';
import type { Middleware } from 'redux';

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyAction = ActionCreatorWithType<any, any, any>;
// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionPayload<T> = T extends ActionCreatorWithType<infer G, any, any> ? G : never;

export interface ReduxListenerDefinition<State extends {}, T extends AnyAction = AnyAction> {
    canHandle: (type: string) => boolean;
    handle: (newState: State, action: ActionPayload<T>, oldState: State) => Promise<void> | void;
}

type ReduxListenerFactoryConfig = {
    actions: string[] | 'any';
};

export const ReduxListenerForConfig = <S extends object>(
    args: ActionTypeListenerInput<S>,
): ReduxListenerDefinition<S, AnyAction> => {
    const { config, handler } = args;
    return {
        canHandle: (type: string) => {
            const allowedTypes = config.actions;
            return config.actions === 'any' || allowedTypes.includes(type);
        },
        handle: handler,
    };
};

export type ActionTypeListenerInput<S extends object> = {
    config: ReduxListenerFactoryConfig;
    handler: ReduxListenerDefinition<S>['handle'];
};

export const createReduxActionListenerMiddleware = <AppState extends {}>(
    listenerInput: ActionTypeListenerInput<AppState>[],
) => {
    const listeners = listenerInput.map(input => ReduxListenerForConfig(input));
    return (store => next => action => {
        const oldState = store.getState();
        next(action);
        const newState = store.getState();
        for (const listener of listeners) {
            if (listener.canHandle(action.type)) {
                void listener.handle(newState, action, oldState);
            }
        }
    }) as Middleware<{}, AppState>;
};
