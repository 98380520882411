/* eslint-disable no-nested-ternary */
import type { BulkSelectedAction } from '../../contexts/ActionsContext';
import { useCustomActionsContext } from '../../contexts/ActionsContext';
import { useTableData } from '../../contexts/TableDataContext';
import {
    useDisplayOptionsContext,
    useToolbarOptionsContext,
    useTranslationOptionsContext,
} from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import { useTableStateAction } from '../../state/action-hooks';
import { useDownloadTableCsv } from '../../utils/export';
import { MUITableFilter } from './Filters/MUITableFilter';
import { MUITableDatePicker } from './MUITableDatePicker';
import { MUITableFixedSearch } from './MUITableFixedSearch';
import { MUITablePopover } from './MUITablePopover';
import { MUITableSearch } from './MUITableSearch';
import { MUITableViewCols } from './MUITableViewCols';
import FilterIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Icon } from '@mui/material';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grid, IconButton, Toolbar, Tooltip, Text, Typography } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
                flexWrap: 'wrap',
                padding: 0,
            },
            row: {
                paddingLeft: 24,
                paddingRight: 24,
                alignItems: 'center',
            },
            topRow: {},
            bottomRow: {},
            selectActive: {
                visibility: 'hidden',
            },
            leftBar: {},
            selectedRows: {
                position: 'relative',
            },
            actions: {
                textAlign: 'right',
            },
            titleRoot: {},
            titleText: {
                textAlign: 'left',
            },
            icon: {
                '&:hover': {
                    color: theme.palette.primary.main,
                },
            },
            iconActive: {
                color: theme.palette.primary.main,
            },
            searchIcon: {
                display: 'inline-flex',
                marginTop: '10px',
                marginRight: '8px',
            },
            [theme.breakpoints.down('md')]: {
                titleRoot: {},
                titleText: {
                    fontSize: '16px',
                },
                spacer: {
                    display: 'none',
                },
                leftBar: {
                    // flex: "1 1 40%",
                    padding: '8px 0px',
                },
                actions: {
                    // flex: "1 1 60%",
                    textAlign: 'right',
                },
            },
            [theme.breakpoints.down('sm')]: {
                root: {
                    display: 'block',
                },
                leftBar: {
                    padding: '8px 0px 0px 0px',
                },
                titleText: {
                    textAlign: 'center',
                },
                actions: {
                    textAlign: 'center',
                },
            },
        }),
    { name: 'MUIDataTableToolbar' },
);

type IconName = 'search' | 'filter' | 'download' | 'viewColumns';

const ToolbarIcon: React.FC<{ name: IconName | string }> = ({ name }) => {
    switch (name) {
        case 'download':
            return <DownloadIcon />;
        case 'filter':
            return <FilterIcon />;
        case 'search':
            return <SearchIcon />;
        case 'viewColumns':
            return <ViewColumnIcon />;
        default:
            return <Icon>{name}</Icon>;
    }
};

interface RootToolbarIconButtonProps {
    tooltipTitle?: string;
    active: boolean;
    children: React.ReactNode;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (e: React.MouseEvent<any>) => void;
    disabled?: boolean;
}

const RootToolbarIconButton: React.FC<RootToolbarIconButtonProps> = props => {
    const classes = useStyles();
    return (
        <Tooltip title={props.tooltipTitle || ''} hidden={!props.tooltipTitle}>
            <IconButton
                disabled={props.disabled}
                size={'small'}
                style={{ marginLeft: 10 }}
                classes={{ root: props.active ? classes.iconActive : classes.icon }}
                onClick={e => props.onClick(e)}
            >
                {props.children}
            </IconButton>
        </Tooltip>
    );
};

interface ToolbarIconButtonProps {
    name: IconName;
    activeIcon?: IconName;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (e: React.MouseEvent<any>) => void;
}

function useIconsVisibility(): { [K in IconName]: boolean } {
    const display = useDisplayOptionsContext();
    return {
        filter: !!display.filter && !display.fixedSearch,
        download: !!display.download,
        search: !!display.search && !display.fixedSearch,
        viewColumns: !!display.viewColumns,
    };
}

export const ToolbarIconButton: React.FC<ToolbarIconButtonProps> = props => {
    const translations = useTranslationOptionsContext();
    const isActive = props.activeIcon === props.name;
    const translationsByName: { [K in IconName]: string } = {
        filter: translations.toolbar.filterTable,
        download: translations.toolbar.downloadCsv,
        search: translations.toolbar.search,
        viewColumns: translations.toolbar.viewColumns,
    };
    const visibility = useIconsVisibility();
    if (!visibility[props.name]) {
        return null;
    }
    return (
        <RootToolbarIconButton tooltipTitle={translationsByName[props.name]} onClick={props.onClick} active={isActive}>
            <ToolbarIcon name={props.name} />
        </RootToolbarIconButton>
    );
};

const ToolbarCustomActions: React.VFC = () => {
    const { global: globalActions, selectedRows: selectedActions } = useCustomActionsContext();
    const selectedRows = useMuiTableSelector(s => s.selectedRows);
    const { displayRows } = useTableData();
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSelectedActionClick = (handler: BulkSelectedAction<any>['onClick']) => () => {
        const selectedRowData = displayRows.filter(d => selectedRows.includes(d.row.id));
        handler(selectedRowData.map(r => r.row));
    };
    const actions = [...(globalActions ?? []), ...(selectedActions && selectedRows.length > 0 ? selectedActions : [])];
    return (
        <>
            {actions
                .filter(a => !a.hidden)
                .map((action, idx) => {
                    const IconComponent = typeof action.icon !== 'string' ? action.icon : undefined;
                    const onClick =
                        action.position === 'toolbar' ? () => action.onClick() : onSelectedActionClick(action.onClick);
                    return (
                        <RootToolbarIconButton
                            key={idx}
                            active={false}
                            disabled={action.disabled}
                            onClick={onClick}
                            tooltipTitle={action.tooltip}
                        >
                            {IconComponent ? <IconComponent /> : <Icon {...action.iconProps}>{action.icon}</Icon>}
                        </RootToolbarIconButton>
                    );
                })}
        </>
    );
};

const ToolbarIconActionsRow: React.FC<{ hideCustom?: boolean }> = _props => {
    const [iconActive, setIconActive] = React.useState<IconName | undefined>();
    const { title } = useDisplayOptionsContext();
    const toggleSearchFn = useTableStateAction('toggleSearchVisible');
    const searchOpen = useMuiTableSelector(s => s.searchOpen);
    const downloadTableCsv = useDownloadTableCsv();
    const toggleSearch = () => {
        toggleSearchFn();
        setIconActive(searchOpen ? undefined : 'search');
    };
    const toggleIcon = (iconName?: IconName, openPopup?: () => void) => () => {
        setIconActive(iconName);
        openPopup?.();
    };
    const iconsVisibility = useIconsVisibility();
    const { global: globalActions, selectedRows: selectedActions } = useCustomActionsContext();
    const anyIconVisible = Object.values(iconsVisibility).includes(true);
    if (!anyIconVisible && globalActions?.length === 0 && selectedActions?.length === 0) {
        return null;
    }
    return (
        <Grid container justifyContent={'flex-end'}>
            <ToolbarIconButton name={'search'} activeIcon={iconActive} onClick={toggleSearch} />
            <ToolbarIconButton name={'download'} activeIcon={iconActive} onClick={() => downloadTableCsv(title)} />
            <MUITablePopover
                refExit={() => setIconActive(undefined)}
                open={iconActive === 'viewColumns'}
                Trigger={triggerProps => (
                    <ToolbarIconButton
                        name={'viewColumns'}
                        activeIcon={iconActive}
                        onClick={toggleIcon('viewColumns', triggerProps.openPopup)}
                    />
                )}
                content={<MUITableViewCols />}
            />
            {/* Dont show filter option if fixed search (built into search bar) */}
            <MUITablePopover
                refExit={() => setIconActive(undefined)}
                open={iconActive === 'filter'}
                Trigger={triggerProps => (
                    <ToolbarIconButton
                        name={'filter'}
                        activeIcon={iconActive}
                        onClick={toggleIcon('filter', triggerProps.openPopup)}
                    />
                )}
                content={<MUITableFilter />}
            />
            <ToolbarCustomActions />
        </Grid>
    );
};

// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export const MUITableToolbar = () => {
    const classes = useStyles();
    const { title, fixedSearch, fixedSearchPosition } = useDisplayOptionsContext();
    const { CustomBottom, CustomFull, CustomLeft, CustomRight, hidden } = useToolbarOptionsContext();
    const searchOpen = useMuiTableSelector(s => s.searchOpen);
    const selectedRows = useMuiTableSelector(s => s.selectedRows);
    const translations = useTranslationOptionsContext();
    if (hidden) {
        return null;
    }

    if (CustomFull) {
        /* Fragment around customToolbarFull prevents errors if fn doesn't return*/
        return (
            <React.Fragment>
                <CustomFull />
            </React.Fragment>
        );
    }
    return (
        <Toolbar className={classes.root} role={'toolbar'} aria-label={'Table Toolbar'}>
            <Grid container className={`${classes.row} ${classes.topRow}`}>
                <Grid item xs={6} className={classes.leftBar}>
                    {fixedSearch && fixedSearchPosition !== 'right' ? (
                        <MUITableFixedSearch />
                    ) : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    CustomLeft ? (
                        <>
                            <CustomLeft />
                        </>
                    ) : searchOpen && !fixedSearch ? (
                        <MUITableSearch />
                    ) : (
                        <div className={classes.titleRoot} aria-hidden={'true'}>
                            <Text variant={'h5'} className={classes.titleText}>
                                {title}
                            </Text>
                            {selectedRows.length > 0 && (
                                <Typography variant={'subtitle1'} className={classes.selectedRows}>
                                    {selectedRows.length}{' '}
                                    {translations.selectedRows.text.replace(
                                        '(s)',
                                        selectedRows.length === 1 ? '' : 's',
                                    )}
                                </Typography>
                            )}
                        </div>
                    )}
                </Grid>
                <Grid item xs={6} className={classes.actions}>
                    {CustomRight ? (
                        <>
                            <Grid container justifyContent={'flex-end'}>
                                <ToolbarIconActionsRow />
                            </Grid>
                            <CustomRight />
                        </>
                    ) : (
                        <>
                            <ToolbarIconActionsRow />
                            {fixedSearch && fixedSearchPosition === 'right' && <MUITableFixedSearch hideTitle={true} />}
                            <MUITableDatePicker />
                        </>
                    )}
                </Grid>
            </Grid>
            {CustomBottom ? (
                <Grid container className={`${classes.row} ${classes.bottomRow}`}>
                    <CustomBottom />
                </Grid>
            ) : null}
        </Toolbar>
    );
};
