import type { BulkDownloadDialogProps, BulkDownloadState, DownloadRecord } from './BulkDownloadDialog.types';
import { useBulkDownloadState } from './BulkDownloadState';
import { Format } from '@orthly/runtime-utils';
import type { ButtonProps } from '@orthly/ui';
import { RootActionDialog, CheckIcon, OrthlyErrorBoundary } from '@orthly/ui';
import { Button, Text, FlossPalette, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';

type DialogTitleButtonProps = { onClick: () => void } & Partial<Omit<ButtonProps, 'onClick'>>;

const DialogTitleButton: React.FC<DialogTitleButtonProps> = ({ onClick, children, ...props }) => (
    <Button variant={'primary'} size={'small'} onClick={onClick} {...props}>
        {children}
    </Button>
);

interface DialogTitleActionsProps {
    state: BulkDownloadState;
    setOpen(open: boolean): void;
    startDownload(): void;
    retryDownload(): void;
}

const DialogTitleActions: React.FC<DialogTitleActionsProps> = ({ state, setOpen, startDownload, retryDownload }) => {
    if (state.files.every(file => file.status === 'idle')) {
        return (
            <DialogTitleButton onClick={startDownload}>
                Download {Format.pluralize('file', state.files.length)}
            </DialogTitleButton>
        );
    }

    const numFailed = state.files.filter(file => file.status === 'error').length;
    if (numFailed > 0 && state.files.every(file => file.status === 'success' || file.status === 'error')) {
        return (
            <div>
                <DialogTitleButton variant={'alert-secondary'} onClick={retryDownload} style={{ marginRight: 8 }}>
                    Retry {Format.pluralize('failed file', numFailed)}
                </DialogTitleButton>
                <DialogTitleButton onClick={() => setOpen(false)}>Done</DialogTitleButton>
            </div>
        );
    }

    if (state.files.every(file => file.status === 'success' || file.status === 'error')) {
        return <DialogTitleButton onClick={() => setOpen(false)}>Done</DialogTitleButton>;
    }

    return (
        <DialogTitleButton disabled={true} onClick={() => {}}>
            Downloading
        </DialogTitleButton>
    );
};

const BulkDownloadDialogInner: React.FC<BulkDownloadDialogProps> = ({
    open,
    setOpen,
    files,
    onDownloadStart = () => {},
    onFileSave,
}) => {
    const [state, { startDownload, retryDownload }] = useBulkDownloadState({
        files,
        onDownloadStart,
        onFileSave,
    });

    if (!open) {
        return null;
    }
    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            loading={false}
            title={'Download files'}
            titleAction={
                <DialogTitleActions
                    state={state}
                    setOpen={setOpen}
                    startDownload={startDownload}
                    retryDownload={retryDownload}
                />
            }
            hideButton={true}
            content={
                <table>
                    <tbody>
                        {state.files.map(file => (
                            <tr key={`${file.path}|${file.name}`} style={{ verticalAlign: 'middle', display: 'flex' }}>
                                <th style={{ textAlign: 'left' }}>
                                    <Text variant={'body2'}>{file.name}</Text>
                                </th>
                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                    {file.status === 'idle' && (
                                        <span style={{ paddingLeft: 8 }}>Ready to download</span>
                                    )}
                                    {(file.status === 'downloading' || file.status === 'downloaded') && (
                                        <>
                                            <div
                                                style={{
                                                    width: '200px',
                                                    height: '1rem',
                                                    marginLeft: 8,
                                                    border: `solid 1px ${FlossPalette.STAR_GRASS}`,
                                                    overflow: 'hidden',
                                                    borderRadius: 8,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: `${file.progress ?? 0}%`,
                                                        height: '1rem',
                                                        background: FlossPalette.STAR_GRASS,
                                                    }}
                                                />
                                            </div>
                                            <span style={{ marginLeft: 8 }}>{file.progress?.toFixed(0) ?? 0}%</span>
                                        </>
                                    )}
                                    {file.status === 'saving' && <span style={{ marginLeft: 8 }}>Saving ...</span>}
                                    {file.status === 'success' && (
                                        <CheckIcon style={{ marginLeft: 8, color: FlossPalette.STAR_GRASS }} />
                                    )}
                                    {file.status === 'error' && (
                                        <span style={{ marginLeft: 8, color: FlossPalette.ATTENTION }}>
                                            {file.error}
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        />
    );
};

const BulkDownloadDialogFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
    return (
        <RootActionDialog
            open={true}
            setOpen={open => {
                if (!open) {
                    resetErrorBoundary();
                }
            }}
            loading={false}
            title={'Download files'}
            hideButton={true}
            content={
                <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
                    <Text variant={'body2'} style={{ marginBottom: 16 }}>
                        Sorry, the file downloader has failed to load. Please try again.
                    </Text>
                    <Button variant={'primary'} onClick={() => resetErrorBoundary()}>
                        Close
                    </Button>
                </Grid>
            }
        />
    );
};

/**
 * This component is a dialog that tracks the download when downloading files from Dandy. Reasons to use this:
 *  - Provide visual feedback to the user to track the progress and status of all downloads.
 *  - Stagger downloads so that Chrome doesn't silently fail with large bulk downloads.
 */
export const BulkDownloadDialog: React.FC<BulkDownloadDialogProps> = props => {
    const { open, files, setOpen } = props;

    // It is simpler to fully remount the dialog when files changes than to try to maintain intermediary states
    // with async state updates
    const filesRef = React.useRef<DownloadRecord[]>([]);
    const [key, setKey] = React.useState(() => uuidv4());
    React.useEffect(() => {
        if (!_.isEqual(filesRef.current, files)) {
            setKey(uuidv4());
            filesRef.current = files;
        }
    }, [files]);

    if (open) {
        return (
            <OrthlyErrorBoundary FallbackComponent={BulkDownloadDialogFallback} onReset={() => setOpen(false)}>
                <BulkDownloadDialogInner key={key} {...props} />
            </OrthlyErrorBoundary>
        );
    }
    return null;
};
