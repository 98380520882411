import type { MaterialUiPickersDate } from '../../SimpleForm';
import { SimpleDatePicker, SimpleDateTimePicker, SimpleTimePicker } from '../../SimpleForm';
import type { FieldDefBasic, FieldDefDate, FieldDefDateTime, FieldDefTime, FieldLayout } from '../QuickForm.types';
import { FormikRootField } from '../QuickForm.types';
import { QFHelperText } from './QFHelperText';
import { FormHelperText, Grid } from '@orthly/ui-primitives';
import type { FieldProps } from 'formik';
import { getIn } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

function FormikDateField(props: {
    field: (FieldDefTime | FieldDefDate | FieldDefDateTime) & { name: string };
    formikProps: FieldProps;
}) {
    const { field, formikProps } = props;
    const [value, setValue] = React.useState<MaterialUiPickersDate>(
        formikProps.field.value && moment(formikProps.field.value).isValid()
            ? moment(formikProps.field.value).toDate()
            : null,
    );
    const error = getIn(formikProps.form.errors, formikProps.field.name);
    const touched = getIn(formikProps.form.touched, formikProps.field.name);
    const formikFieldVal = formikProps.field.value;
    React.useEffect(() => {
        if (formikProps.field.value && moment(formikProps.field.value).isValid() && !touched) {
            formikProps.form.setFieldTouched(field.name);
        }
    }, [formikProps, field.name, touched]);
    React.useEffect(() => {
        const newValue =
            formikProps.field.value && moment(formikProps.field.value).isValid()
                ? moment(formikProps.field.value).toDate()
                : null;
        if (newValue !== value) {
            setValue(newValue);
        }
        // we'll enter an infinite loop if we adjust the value every time the stateful value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikProps.field.value]);
    const commonPickerProps = {
        value,
        label: field.label || _.startCase(field.name),
        onChange: (v: MaterialUiPickersDate) => {
            setValue(v);
            const newFormValue = !v || !moment(v).isValid() ? null : v.toJSON();
            if (formikFieldVal !== newFormValue) {
                formikProps.form.setFieldValue(formikProps.field.name, newFormValue);
            }
        },
        fullWidth: true,
        required: !field.optional,
        'data-test': `quick-form-field-${field.name}`,
        slotProps: {
            textField: {
                onBlur: () => formikProps.form.setFieldTouched(formikProps.field.name, true),
            },
        },
        // PickerProps: {
        //     fullWidth: true,
        //     onBlur: () => formikProps.form.setFieldTouched(formikProps.field.name, true),
        //     InputLabelProps: { required: !field.optional },
        //     'data-test': `quick-form-field-${field.name}`,
        // },
    };
    const showError = !!error && touched;
    return (
        <Grid container style={{ minHeight: 70 }}>
            {(() => {
                switch (field.type) {
                    case 'date':
                        return <SimpleDatePicker {...commonPickerProps} />;
                    case 'time':
                        return <SimpleTimePicker {...commonPickerProps} />;
                    case 'datetime':
                        return <SimpleDateTimePicker {...commonPickerProps} />;
                    default:
                        // this should never happen
                        return null;
                }
            })()}
            {showError ? (
                <FormHelperText error={true}>{error}</FormHelperText>
            ) : (
                <QFHelperText text={field.helperText} />
            )}
        </Grid>
    );
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateDateField = (field: FieldDefBasic<any> & { name: string }) => (value: any) => {
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    return value === null && field.optional ? undefined : moment(value).isValid() ? undefined : 'Must be a valid date';
};

type QFDateFieldField = (FieldDefDate | FieldDefDateTime | FieldDefTime) & { name: string };

interface QFDateFieldProps {
    field: QFDateFieldField;
}

export const QFDateField: React.FC<QFDateFieldProps> = ({ field }) => {
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    return (
        <Grid item {...layout}>
            <div style={{ display: field.hidden ? 'none' : undefined }}>
                <FormikRootField
                    name={field.name}
                    render={formikProps => <FormikDateField field={field} formikProps={formikProps} />}
                    validate={validateDateField(field)}
                />
            </div>
        </Grid>
    );
};
