import { useTableStateAction } from '../../state/action-hooks';
import type { Row } from '../../types';
import { useDetailPanelOpen } from '../../utils/useDetailPanelOpen';
import { BodyCellBase } from './MUITableBodyCell';
import { IconButton, DropdownIcon } from '@orthly/ui-primitives';
import React from 'react';

interface MUITableDetailCellProps {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    row: Row<any>;
}

export const MUITableDetailCell = (props: MUITableDetailCellProps) => {
    const { row } = props;
    const toggleDetailPanel = useTableStateAction('toggleDetailPanel');
    const isOpen = useDetailPanelOpen(row.id);
    const handleClick = () => {
        toggleDetailPanel(row);
    };
    return (
        <BodyCellBase colSpan={1}>
            <IconButton
                size={'small'}
                onClick={handleClick}
                style={{ transition: 'all 200ms ease 0s', transform: isOpen ? 'rotate(90deg)' : undefined }}
            >
                <DropdownIcon />
            </IconButton>
        </BodyCellBase>
    );
};
