import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlLabSalesOrderServiceSpeed } from '@orthly/graphql-schema';
import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const LabPortalOrderDetailTrackingNoRushBannerSalesOrder_Fragment = graphql(`
    fragment LabPortalOrderDetailTrackingNoRushBannerSalesOrder_Fragment on SalesOrderDTO {
        service_level {
            speed
        }
    }
`);

const Wrapper = styled('div')(() => ({
    margin: '8px 0px 8px -24px',
    width: 'calc(100% + 48px)',
    padding: '8px 16px',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 80,
    containerType: 'inline-size',
    backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
}));

interface OrderDetailTrackingServiceLevelSpeedBannerContentProps {
    speed: string | undefined;
}

export const OrderDetailTrackingServiceLevelSpeedBannerContent: React.VFC<
    OrderDetailTrackingServiceLevelSpeedBannerContentProps
> = ({ speed }) => {
    // We only show rushes to the lab
    if (speed !== LabsGqlLabSalesOrderServiceSpeed.Rush) {
        return null;
    }

    return (
        <Wrapper>
            <Text variant={'body2'} medium color={'SECONDARY_FOREGROUND'}>
                This is a rush order that needs to ship with next day air
            </Text>
        </Wrapper>
    );
};

interface OrderDetailTrackingServiceLevelSpeedBannerProps {
    salesOrderFragment: FragmentType<typeof LabPortalOrderDetailTrackingNoRushBannerSalesOrder_Fragment>;
}

export const OrderDetailTrackingServiceLevelSpeedBanner: React.VFC<OrderDetailTrackingServiceLevelSpeedBannerProps> = ({
    salesOrderFragment,
}) => {
    const salesOrder = getFragmentData(LabPortalOrderDetailTrackingNoRushBannerSalesOrder_Fragment, salesOrderFragment);
    const speed = salesOrder?.service_level.speed;
    return <OrderDetailTrackingServiceLevelSpeedBannerContent speed={speed} />;
};
