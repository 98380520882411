import type { MUITableBaseProps } from '../components/MUITableRoot';
import type {
    DisplayOptions,
    MUIDataObj,
    PaginationOptions,
    RowOptions,
    ToolbarOptions,
    TranslationOptions,
} from '../types';
import { DEFAULT_TRANSLATIONS } from '../utils/translations';
import React from 'react';

interface AdditionalDisplayOpts<R extends MUIDataObj> {
    title: string;
    DetailPanel?: React.ComponentType<{ data: R }>;
}

interface IDisplayOptionsContext<R extends MUIDataObj> extends DisplayOptions, AdditionalDisplayOpts<R> {}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DisplayOptionsContext = React.createContext<IDisplayOptionsContext<any>>({ title: '' });
// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DisplayOptionsProvider: React.FC<{ opts?: DisplayOptions } & AdditionalDisplayOpts<any>> = props => {
    return (
        <DisplayOptionsContext.Provider value={{ ...props.opts, title: props.title, DetailPanel: props.DetailPanel }}>
            {props.children}
        </DisplayOptionsContext.Provider>
    );
};

export function useDisplayOptionsContext() {
    return React.useContext(DisplayOptionsContext);
}

const TranslationOptionsContext = React.createContext<TranslationOptions>(DEFAULT_TRANSLATIONS);
const TranslationOptionsProvider: React.FC<{ opts?: Partial<TranslationOptions> }> = props => {
    return (
        <TranslationOptionsContext.Provider value={{ ...DEFAULT_TRANSLATIONS, ...props.opts }}>
            {props.children}
        </TranslationOptionsContext.Provider>
    );
};

export function useTranslationOptionsContext() {
    return React.useContext(TranslationOptionsContext);
}

const PaginationOptionsContext = React.createContext<PaginationOptions>({});
const PaginationOptionsProvider: React.FC<{ opts?: PaginationOptions }> = props => {
    return (
        <PaginationOptionsContext.Provider value={{ disable: false, ...props.opts }}>
            {props.children}
        </PaginationOptionsContext.Provider>
    );
};

export function usePaginationOptionsContext() {
    return React.useContext(PaginationOptionsContext);
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RowOptionsContext = React.createContext<RowOptions<any>>({});
// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RowOptionsProvider: React.FC<{ opts?: RowOptions<any> }> = props => {
    return <RowOptionsContext.Provider value={{ ...props.opts }}>{props.children}</RowOptionsContext.Provider>;
};

export function useRowOptionsContext() {
    return React.useContext(RowOptionsContext);
}

const ToolbarOptionsContext = React.createContext<ToolbarOptions>({
    showDates: false,
});
const ToolbarOptionsProvider: React.FC<{ opts?: ToolbarOptions }> = props => {
    return (
        <ToolbarOptionsContext.Provider value={{ showDates: false, ...props.opts }}>
            {props.children}
        </ToolbarOptionsContext.Provider>
    );
};

export function useToolbarOptionsContext() {
    return React.useContext(ToolbarOptionsContext);
}

export function TableOptionsContextProvider<R extends MUIDataObj>(
    props: MUITableBaseProps<R> & { children: React.ReactNode },
) {
    return (
        <TranslationOptionsProvider opts={props.translationOptions}>
            <PaginationOptionsProvider opts={props.paginationOptions}>
                <ToolbarOptionsProvider opts={props.toolbarOptions}>
                    <RowOptionsProvider opts={props.rowOptions}>
                        <DisplayOptionsProvider
                            title={props.title}
                            DetailPanel={props.DetailPanel}
                            opts={props.displayOptions}
                        >
                            {props.children}
                        </DisplayOptionsProvider>
                    </RowOptionsProvider>
                </ToolbarOptionsProvider>
            </PaginationOptionsProvider>
        </TranslationOptionsProvider>
    );
}
