import type { PopoverActions, PopoverProps } from '@orthly/ui-primitives';
import { Popover } from '@orthly/ui-primitives';
import type { ReactNode } from 'react';
import React from 'react';

export interface MUITablePopoverProps extends PopoverProps {
    Trigger: React.FC<{ openPopup: () => void }>;
    refExit?: () => void;
    content: ReactNode;
}

export const MUITablePopover: React.FC<MUITablePopoverProps> = props => {
    const { Trigger, content, className } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const anchorEl = React.useRef<HTMLDivElement | null>(null);
    const popoverActions = React.useRef<null | PopoverActions>(null);

    const handleClick = () => {
        setOpen(!open);
        setTimeout(() => {
            popoverActions.current && popoverActions.current.updatePosition();
        }, 100);
    };
    const handleOnExit = () => {
        props.refExit?.();
    };
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRequestClose = (cb: any) => {
        setOpen(false);
        cb && typeof cb === 'function' && cb();
    };

    return (
        <React.Fragment>
            <Popover
                className={className}
                action={popoverActions}
                elevation={2}
                open={open && props.open}
                onClose={handleRequestClose}
                TransitionProps={{
                    onExited: handleOnExit,
                }}
                anchorEl={anchorEl.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                {content}
            </Popover>
            <div ref={anchorEl} style={{ display: 'inline', height: 1, width: 1 }} />
            <Trigger openPopup={() => handleClick()} />
        </React.Fragment>
    );
};
