import { ManufacturerActions } from './manufacturer.actions.graphql';
import { handleActions } from 'redux-actions';

export type ManufacturerOrderScreenWithCount =
    | 'New_V2'
    | 'Intake'
    | 'Nested_Intake'
    | 'Nesting'
    | 'Design_V2'
    | 'Updates_V2'
    | 'Expedite_V2'
    | 'OnHold_V2'
    | 'Returns_V2';

export type ManufacturerScreen =
    | ManufacturerOrderScreenWithCount
    | 'Overview'
    | 'Nesting'
    | 'Profile'
    | 'Billing'
    | 'MetalPricing'
    | 'BatchShipments'
    | 'Booking';

interface ManufacturerScreenState {
    screenSelection: ManufacturerScreen;
}

export interface ManufacturerState {
    screen: ManufacturerScreenState;
    readOrderEventIds: number[];
    dismissedLabSlipWarningOrderIds: string[];
    search?: string;
}

const initialState: ManufacturerState = {
    screen: { screenSelection: 'Overview' },
    readOrderEventIds: [],
    dismissedLabSlipWarningOrderIds: [],
};

export const manufacturerReducer = handleActions<ManufacturerState, any>(
    {
        ...ManufacturerActions.SET_SCREEN.reducer<ManufacturerState>((state, action) => ({
            ...state,
            screen: { ...state.screen, screenSelection: action.payload.screen },
        })),
        ...ManufacturerActions.DISMISS_OUTDATED_LAB_SLIP_WARNING.reducer<ManufacturerState>((state, action) => ({
            ...state,
            dismissedLabSlipWarningOrderIds: [...state.dismissedLabSlipWarningOrderIds, action.payload],
        })),
    },
    initialState,
);
